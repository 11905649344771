import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const AcademicSetup = React.lazy(
  () => import('modules/master/academic/academicSetup')
);

const routes = {
  element: <AcademicSetup />,
  title: 'Academic Setup',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    '/academic/branches',
    '/academic/academicyears',
    '/academic/branchcities',
  ],
};

export default routes;
