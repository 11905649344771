import React from 'react';
import { Box } from '@mui/material';
import appLogo from 'assets/public/app-logo.png';

const AppLogo = ({ sx }) => {
  const styles = {
    height: { xs: 56, sm: 70, lg: 87 },
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    ml: 3,
    '& img': {
      height: '90%',
      width: '100%',
      objectFit: 'contain',
    },
  };
  return (
    <Box sx={sx ? { ...styles, ...sx } : styles} className="app-logo">
      <img srcSet={appLogo} src={appLogo} alt="logo" loading="lazy" />
    </Box>
  );
};

export default AppLogo;
