import React, { useEffect, useState } from 'react';
import AppContentView from '../../AppContentView';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import PublicLayoutWrapper from './PublicLayoutWrapper';
import MainContent from './MainContent';

import PublicLayoutContainer from './PublicLayoutContainer';
import { useLocation } from 'react-router-dom';
import PropsTypes from 'prop-types';
import { useAppSettingContext } from 'contexts/appSetting';

const PublicLayout = ({ routes, routesConfig }) => {
  const { pathname } = useLocation();
  const { appSetting } = useAppSettingContext();
  const [isNavCollapsed, setNavCollapsed] = useState(false);

  const toggleNavCollapsed = () => {
    setNavCollapsed(!isNavCollapsed);
  };
  useEffect(() => {
    if (isNavCollapsed) setNavCollapsed(!isNavCollapsed);
  }, [pathname]);

  return (
    <PublicLayoutContainer>
      <PublicLayoutWrapper>
        <AppSidebar
          routesConfig={routesConfig}
          isNavCollapsed={isNavCollapsed}
          toggleNavCollapsed={toggleNavCollapsed}
          sidebarTextColor={appSetting?.sidebar?.colorSet?.sidebarTextColor}
        />

        <MainContent>
          <AppHeader
            toggleNavCollapsed={toggleNavCollapsed}
            routesConfig={routesConfig}
          />
          <AppContentView routes={routes} />
          <AppFooter footer={appSetting?.footer} />
        </MainContent>
      </PublicLayoutWrapper>
    </PublicLayoutContainer>
  );
};

export default PublicLayout;
PublicLayout.propTypes = {
  routes: PropsTypes.object.isRequired,
  routesConfig: PropsTypes.array.isRequired,
};
