import { USER_ROLE } from 'constants/AppConstant';
import { MdAccountBalanceWallet } from 'react-icons/md';
import accountSummaryRoutes from './accountSummaryRoutes';
import feesConcessionVerificationRoutes from './feesConcessionVerificationRoutes';
import feesConcessionRoutes from './feesConcesssionRoutes';
import paymentTransactionDateRoutes from './paymentTransactionDateRoutes';
import paymentTransactionRoutes from './paymentTransactionRoutes';
import paymentTransactionSummaryByDivisionRoutes from './paymentTransactionSummaryByDivisionRoutes';
import paymentTransactionSummaryRoutes from './paymentTransactionSummaryRoutes';
import studentFeesReceiptRoutes from './studentFeesReceiptRoutes';
import studentFeesReportRoutes from './studentFeesReportRoutes';
import studentFeesStructureInstallmentByDueDate from './studentFeesStructureInstallmentByDueDate';
import studentFeesSummaryRoutes from './studentFeesSummaryRoutes';

const menu = {
  id: 'Account',
  title: 'Account',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: 'collapse',
  icon: <MdAccountBalanceWallet />,
  path: ['/accountMenu'],
  children: [
    studentFeesReceiptRoutes,
    studentFeesSummaryRoutes,
    studentFeesStructureInstallmentByDueDate,
    studentFeesReportRoutes,
    paymentTransactionDateRoutes,
    paymentTransactionRoutes,
    paymentTransactionSummaryRoutes,
    paymentTransactionSummaryByDivisionRoutes,

    feesConcessionRoutes,
    accountSummaryRoutes,
    feesConcessionVerificationRoutes,
  ],
};

export default menu;
