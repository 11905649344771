import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const FeesReceiptList = React.lazy(
  () => import('modules/commonFeesPayment/FeesReceiptList')
);

const routes = {
  permittedRole: [USER_ROLE.STUDENT, USER_ROLE.SUPER_ADMIN],
  path: ['/student/feesreceiptlist'],
  element: <FeesReceiptList />,
};

export default routes;
