import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const CompetitiveExamStatistics = React.lazy(
  () => import('modules/competitiveExam/competitiveExamStatistics')
);

const routes = {
  element: <CompetitiveExamStatistics />,
  title: 'Statistics',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/competitiveexamstatistics'],
};

export default routes;
