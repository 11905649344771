import React from 'react';

// MUI Components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';

// Crema Components
import { FONTS } from 'constants/AppEnum';

export const renderParagraphs = (list, sx = null) =>
  list?.map((item, index) => (
    <Typography
      key={index}
      component="p"
      sx={sx ? sx : { my: 3, textAlign: 'justify' }}
    >
      {item}
    </Typography>
  ));

export const renderHeading = (heading, size = 20, sx = null) => (
  <Typography
    component="h1"
    sx={sx ? sx : { fontWeight: FONTS.BOLD, fontSize: size }}
  >
    {heading}
  </Typography>
);

export const renderList = (list, type = 'ul', icon = 'disc', sx = null) => (
  <List
    component={type}
    sx={
      sx
        ? sx
        : {
            pl: 10,
            '& .MuiListItem-root': {
              display: 'list-item',
              listStyle: `${icon}`,
              p: 0,
            },
          }
    }
  >
    {list?.map((item, index) => (
      <ListItem key={index}>
        <ListItemText
          sx={{ textAlign: 'justify' }}
          primary={item}
        ></ListItemText>
      </ListItem>
    ))}
  </List>
);
