import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const EntranceStudentRegistrationAdmin = React.lazy(
  () => import('modules/student/entrance/entranceStudentRegistrationAdmin')
);

const routes = {
  title: 'Registration',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/entrance/registration'],
  element: <EntranceStudentRegistrationAdmin />,
  type: 'item',
};

export default routes;
