import React from 'react';

const AdmissionStudentProfile = React.lazy(
  () => import('modules/student/admission/admissionStudentProfile')
);
const routes = {
  path: ['/student/profile'],
  element: <AdmissionStudentProfile />,
};

export default routes;
