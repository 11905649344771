// React imports
import { createContext, useContext, useReducer } from 'react';

// Generic API helper
import ApiHelper from 'helpers/ApiHelper';

// Constant API names and default api params
import { API_NAMES } from 'constants/ApiConstants';

// Reducer for changing state
import { DISPATCH_TYPES } from 'constants/ActionTypes';
import reducer from 'reducers/user/userRegistrationReducer';

// context to be used in the app
const UserRegistrationContext = createContext();

// hook to use the context states in the ui
const useUserRegistrationContext = () => {
  return useContext(UserRegistrationContext);
};
export { useUserRegistrationContext };

// Initial UserRegistration
const initialUserRegistration = {
  data: [],
  count: 0,
  item: {},
};

// provider of the context
const UserRegistrationProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { GetListAPI, GetAPI, UpdateAPI, CreateAPI, DeleteAPI } = ApiHelper();

  // Reducer to set state
  const [state, dispatch] = useReducer(reducer, initialUserRegistration);

  // Name of the api to hit
  const apiName = API_NAMES.USER.USER;

  // Fetches whole records from DB
  const getUserIDCardList = (params, callbackFunction) => {
    return GetListAPI(
      `${apiName}idcards`,
      dispatch,
      params,
      callbackFunction,
      null,
      'G'
    );
  };

  // Fetches whole records from DB
  const getList = (params, callbackFunction) => {
    return GetListAPI(apiName, dispatch, params, callbackFunction);
  };

  // Fetches single record in records of DB
  const getItem = (_id, params, onSuccess, onError) => {
    GetAPI(apiName, dispatch, _id, params, onSuccess, onError);
  };

  // Update single record in records of DB
  const updateItem = (formData, setSubmitting, resetForm, handleAddClose) => {
    UpdateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose
    );
  };

  // Add new record in records of DB
  const createItem = (
    formData,
    setSubmitting,
    resetForm,
    handleAddClose,
    callbackFunction
  ) => {
    CreateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose,
      '',
      (data) => {
        setItem(data);
        callbackFunction(data);
      }
    );
  };

  // Delete on or may records from records in DB
  const deleteItems = (params, _ids, onSuccess, onError) => {
    DeleteAPI(apiName, dispatch, params, _ids, '', onSuccess, onError);
  };

  const setItem = (item) => {
    dispatch({
      type: DISPATCH_TYPES.SET_ITEM,
      payload: { data: item },
    });
  };

  return (
    <UserRegistrationContext.Provider
      value={{
        data: state.data,
        count: state.count,
        item: state.item,
        getUserIDCardList,
        getList,
        getItem,
        updateItem,
        createItem,
        deleteItems,
        setItem,
      }}
    >
      {children}
    </UserRegistrationContext.Provider>
  );
};

export default UserRegistrationProvider;
