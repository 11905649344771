import AppGridContainer from '@crema/components/AppGridContainer';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FONTS } from 'constants/AppEnum';
import PaymentTerms from 'modules/public/guidelines/PaymentTerms';
import PrivacyPolicy from 'modules/public/guidelines/PrivacyPolicy';
import TermsAndConditions from 'modules/public/guidelines/TermsAndConditions';
import { useState } from 'react';
import { FaFacebookF, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import FooterWrapper from './FooterWrapper';

const AppFooter = ({ footer }) => {
  // States of registration page
  const [isTermsAndConditionsOpen, setIsTermsAndConditionsOpen] =
    useState(false);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const [isPaymentTermsOpen, setIsPaymentTermsOpen] = useState(false);

  return (
    <>
      {footer && (
        <FooterWrapper className="footer ">
          <Box sx={{ backgroundColor: 'hsl(14, 98%, 4%)' }}>
            <TermsAndConditions
              setIsTermsAndConditionsOpen={setIsTermsAndConditionsOpen}
              isTermsAndConditionsOpen={isTermsAndConditionsOpen}
            />
            <PrivacyPolicy
              setIsPrivacyPolicyOpen={setIsPrivacyPolicyOpen}
              isPrivacyPolicyOpen={isPrivacyPolicyOpen}
            />
            <PaymentTerms
              setIsPaymentTermsOpen={setIsPaymentTermsOpen}
              isPaymentTermsOpen={isPaymentTermsOpen}
            />
          </Box>

          <AppGridContainer
            spacing={3}
            className="flex-center"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto 0px auto ',
              padding: '15px',
              width: '100%',
              maxWidth: 1020,
            }}
          >
            <Grid item xs={12} md={6} lg={6}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    component="span"
                    sx={{
                      cursor: 'pointer',
                      color: 'primary.contrastText',
                      fontWeight: FONTS.BOLD,
                      fontSize: 14,
                    }}
                    onClick={() => setIsTermsAndConditionsOpen(true)}
                  >
                    Terms & Conditions
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    component="span"
                    sx={{
                      cursor: 'pointer',
                      color: 'primary.contrastText',
                      fontWeight: FONTS.BOLD,
                      fontSize: 14,
                    }}
                    onClick={() => setIsPrivacyPolicyOpen(true)}
                  >
                    Privacy Policy
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    component="span"
                    sx={{
                      cursor: 'pointer',
                      color: 'primary.contrastText',
                      fontWeight: FONTS.BOLD,
                      fontSize: 14,
                    }}
                    onClick={() => setIsPaymentTermsOpen(true)}
                  >
                    Payment Terms
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Grid
                container
                sx={{
                  '& a': {
                    color: 'primary.contrastText',
                  },
                }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    component="span"
                    sx={{
                      cursor: 'pointer',

                      fontWeight: FONTS.BOLD,
                      fontSize: 14,
                    }}
                  >
                    <Link to="/about">About Us</Link>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    component="span"
                    sx={{
                      cursor: 'pointer',
                      color: 'primary.contrastText',
                      fontWeight: FONTS.BOLD,
                      fontSize: 14,
                    }}
                  >
                    <Link to="/admission/brochure">Brochure</Link>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    component="span"
                    sx={{
                      cursor: 'pointer',
                      color: 'primary.contrastText',
                      fontWeight: FONTS.BOLD,
                      fontSize: 14,
                    }}
                  >
                    <Link to="/contactus">Contact Us</Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </AppGridContainer>

          <Box className="footerContainer">
            <Typography textAlign={'center'}>
              NETIZENS FOUNDATION ACADEMY © All Rights Reserved.
            </Typography>
            <Box
              sx={{
                my: 3,
                svg: {
                  fontSize: 40,
                  mx: 2,
                  p: 2,
                  borderRadius: '50%',
                  color: 'primary.contrastText',
                },
                '.whatsapp': { backgroundColor: '#44c052' },
                '.youtube': { backgroundColor: '#f60002' },
                '.facebook': { backgroundColor: '#0862f7' },
              }}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/+918308380033?text=Hello%20I%20Want%20to%20get%20details!"
              >
                <FaWhatsapp className="whatsapp" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCIzIl9JbHf5WeL76-weRUhg"
              >
                <FaYoutube className="youtube" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/OfficialNetizensFoundationAcademy"
              >
                <FaFacebookF className="facebook" />
              </a>
            </Box>
          </Box>
        </FooterWrapper>
      )}
    </>
  );
};

export default AppFooter;

const branchAddresses = [
  {
    heading: 'Main Branch',
    location: (
      <>
        Near Maharashtra Jeevan Pradhikaran (Water Tank), <br /> Barshi Road,
        Latur-413531, Maharashtra, India
      </>
    ),
    phone: <>+91 83 08 38 00 33</>,
    email: <>principal.netizens@gmail.com</>,
    locationLink: 'https://maps.app.goo.gl/psB682LdZWpSXyAE9',
    phoneLink: '+918308380033',
    emailLink: 'principal.netizens@gmail.com',
  },
  {
    heading: 'Ausa Road Branch',
    location: (
      <>
        Ganesh Nagar, Near Lagaskar Building, <br /> In front of Somvanshi
        Childcare Hospital, <br />
        Old Ausa Road, Latur-413531, Maharashtra, India
      </>
    ),
    phone: <>+91 84 23 43 23 23</>,
    email: <>principal.netizens@gmail.com</>,
    locationLink: 'https://maps.app.goo.gl/bCRnjWdmGGpKeW977',
    phoneLink: '+918423432323',
    emailLink: 'principal.netizens@gmail.com',
  },
];
