import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const CAS = React.lazy(() => import('modules/attendance/cas'));

const routes = {
  element: <CAS />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/attendance/casreport'],
  title: 'CAS Report',
  type: 'item',
};

export default routes;
