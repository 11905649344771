import PropTypes from 'prop-types';
import AcademicMasterProvider from './academic';
import CommunicationMasterProvider from './communication';
import QuestionBankProvider from './questionBank';
import SystemMasterProvider from './system';
import DesignationProvider from './system/designationContext';
import LockerProvider from './system/lockerContext';

const MasterContextProvider = ({ children }) => {
  return (
    <LockerProvider>
      <DesignationProvider>
        <CommunicationMasterProvider>
          <QuestionBankProvider>
            <SystemMasterProvider>
              <AcademicMasterProvider>{children}</AcademicMasterProvider>
            </SystemMasterProvider>
          </QuestionBankProvider>
        </CommunicationMasterProvider>
      </DesignationProvider>
    </LockerProvider>
  );
};

export default MasterContextProvider;

MasterContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
