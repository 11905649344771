// dispatch types is constant object for dispatch types in reducer
import { DISPATCH_TYPES } from 'constants/ActionTypes';

const reducer = (state, action) => {
  switch (action.type) {
    // It will set all fetched records in state
    case DISPATCH_TYPES.GET_ALL:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count,
      };

    // It will set state for created record
    case DISPATCH_TYPES.CREATE:
      return {
        ...state,
        data: [...state.data, action.payload],
        count: state.count + 1,
      };

    // It will set state for deleted records
    case DISPATCH_TYPES.DELETE:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count,
      };

    // It will set state for updated record
    case DISPATCH_TYPES.UPDATE:
      const newData = [];
      const data = state.data;
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].student_admission_id === action.payload.student_admission_id
        ) {
          if (data[i].parallel_reservations?.length > 1) {
            data[i].parallel_reservations = data[
              i
            ].parallel_reservations.filter(
              (item) =>
                item?.parallel_reservation_id !==
                action.payload.parallel_reservation_id
            );
            newData.push(data[i]);
          }
        } else {
          newData.push(data[i]);
        }
      }
      state.data = newData;
      return state;

    // It will set all fetched records in state
    case DISPATCH_TYPES.GET:
      return {
        ...state,
        item: action.payload.data,
      };

    // If no case matches then return same state
    default:
      return state;
  }
};

export default reducer;
