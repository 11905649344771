import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const AssignmentDataUpload = React.lazy(
  () => import('modules/assignment/assignmentDataUpload')
);

const routes = {
  element: <AssignmentDataUpload />,
  title: 'Update Assignment',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/assignmentdatauploads'],
};

export default routes;
