import { USER_ROLE } from 'constants/AppConstant';
import { FaNewspaper } from 'react-icons/fa6';

import entranceStudentCommunicationRoutes from './entranceStudentCommunicationRoutes';
import entranceStudentRegistrationAdminRoutes from './entranceStudentRegistrationAdminRoutes';
import entranceStudentRegistrationListRoutes from './entranceStudentRegistrationListRoutes';
import entranceStudentResultRoutes from './entranceStudentResultRoutes';
import entranceStudentStatisticsRoutes from './entranceStudentStatisticsRoutes';
import entranceTestScheduleRoutes from './entranceTestScheduleRoutes';

const routes = {
  title: 'Entrance ',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: 'collapse',
  icon: <FaNewspaper />,
  path: ['/entranceMenu'],
  children: [
    entranceTestScheduleRoutes,
    entranceStudentRegistrationAdminRoutes,
    entranceStudentRegistrationListRoutes,
    entranceStudentResultRoutes,
    entranceStudentCommunicationRoutes,
    entranceStudentStatisticsRoutes,
  ],
};

export default routes;
