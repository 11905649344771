import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const AssignmentSetup = React.lazy(
  () => import('modules/assignment/assignmentSetup')
);

const routes = {
  element: <AssignmentSetup />,
  title: 'Assignment Setup',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    '/assignments',
    '/assignment/types',
    '/assignment/groups',
    '/assignment/headers',
  ],
};

export default routes;
