import PropTypes from 'prop-types';
import AssignmentProvider from './assignmentContext';
import AssignmentDataUploadProvider from './assignmentDataUploadContext';
import AssignmentGroupProvider from './assignmentGroupContext';
import AssignmentPaperProvider from './assignmentPaperContext';
import AssignmentQuestionGroupProvider from './assignmentQuestionGroupContext';
import AssignmentSummaryProvider from './assignmentSummaryContext';
import AssignmentTypeProvider from './assignmentTypeContext';
import AssignmentTypeHeaderProvider from './assignmentTypeHeaderContext';
import PublishAssignmentProvider from './publishAssignmentContext';
import StudentAssignmentProvider from './studentAssignmentContext';

const AssignmentContextProvider = ({ children }) => {
  return (
    <AssignmentGroupProvider>
      <AssignmentTypeHeaderProvider>
        <AssignmentTypeProvider>
          <AssignmentProvider>
            <AssignmentQuestionGroupProvider>
              <PublishAssignmentProvider>
                <StudentAssignmentProvider>
                  <AssignmentDataUploadProvider>
                    <AssignmentSummaryProvider>
                      <AssignmentPaperProvider>
                        {children}
                      </AssignmentPaperProvider>
                    </AssignmentSummaryProvider>
                  </AssignmentDataUploadProvider>
                </StudentAssignmentProvider>
              </PublishAssignmentProvider>
            </AssignmentQuestionGroupProvider>
          </AssignmentProvider>
        </AssignmentTypeProvider>
      </AssignmentTypeHeaderProvider>
    </AssignmentGroupProvider>
  );
};

export default AssignmentContextProvider;

AssignmentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
