// React imports
import { createContext, useContext, useReducer } from 'react';

// Generic API helper
import ApiHelper from 'helpers/ApiHelper';

// Constant API names and default api params
import { API_NAMES } from 'constants/ApiConstants';

// Reducer for changing state
import { DISPATCH_TYPES } from 'constants/ActionTypes';
import reducer, {
  SET_STUDENT_ID_CARD_DATA,
} from 'reducers/student/admission/admissionStudentAdmissionReducer';
import { useAdmissionStudentAdmissionQueueContext } from './admissionStudentAdmissionQueueContext';

// context to be used in the app
const AdmissionStudentAdmissionContext = createContext();

// hook to use the context states in the ui
const useAdmissionStudentAdmissionContext = () => {
  return useContext(AdmissionStudentAdmissionContext);
};
export { useAdmissionStudentAdmissionContext };

// Initial State
const initialState = {
  data: [],
  count: 0,
  item: {},
  exportList: [],
  studentIdCardData: {},
  rollNumberList: [],
};

// provider of the context
const AdmissionStudentAdmissionProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { GetListAPI, GetAPI, UpdateAPI, CreateAPI, DeleteAPI } = ApiHelper();
  const { setItem } = useAdmissionStudentAdmissionQueueContext();

  // Reducer to set state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Name of the api to hit
  const apiName = API_NAMES.STUDENT.ADMISSION.ADMISSION_STUDENT_ADMISSION;

  // Fetches whole records from DB
  const getList = (params, callbackFunction) => {
    return GetListAPI(apiName, dispatch, params, callbackFunction);
  };

  const getRollNumberList = (params, callbackFunction) => {
    return GetListAPI(
      apiName,
      dispatch,
      params,
      callbackFunction,
      null,
      'ROLL_NUMBER_LIST'
    );
  };

  const saveRollNumberList = (
    formData,
    setSubmitting,
    resetForm,
    handleAddClose
  ) => {
    UpdateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose,
      null,
      null,
      'UPDATE_ROLL_NUMBER_LIST'
    );
  };

  // Fetches whole records from DB
  const getStudentIDCardList = (params, callbackFunction) => {
    return GetListAPI(
      `${apiName}idcards`,
      dispatch,
      params,
      callbackFunction,
      null,
      SET_STUDENT_ID_CARD_DATA
    );
  };

  // Fetches whole records from DB
  const getExportList = (params, callbackFunction) => {
    return GetListAPI(
      apiName + 'export',
      dispatch,
      params,
      callbackFunction,
      null,
      DISPATCH_TYPES.EXPORT
    );
  };

  // Fetches single record in records of DB
  const getItem = (_id, params, onSuccess, onError) => {
    GetAPI(apiName, dispatch, _id, params, onSuccess, onError);
  };

  // Update single record in records of DB
  const updateItem = (formData, setSubmitting, resetForm, handleAddClose) => {
    UpdateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose
    );
  };

  // Add new record in records of DB
  const createItem = (formData, setSubmitting, resetForm, handleAddClose) => {
    CreateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose,
      '',
      setItem
    );
  };

  // Delete on or may records from records in DB
  const deleteItems = (params, _ids, onSuccess, onError) => {
    DeleteAPI(apiName, dispatch, params, _ids, '', onSuccess, onError);
  };

  return (
    <AdmissionStudentAdmissionContext.Provider
      value={{
        data: state.data,
        count: state.count,
        item: state.item,
        exportList: state.exportList,
        studentIdCardData: state.studentIdCardData,
        rollNumberList: state.rollNumberList,
        getStudentIDCardList,
        dispatch,
        getList,
        getItem,
        updateItem,
        createItem,
        deleteItems,
        getExportList,
        getRollNumberList,
        saveRollNumberList,
      }}
    >
      {children}
    </AdmissionStudentAdmissionContext.Provider>
  );
};

export default AdmissionStudentAdmissionProvider;
