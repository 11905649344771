import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const PaymentTransactionDate = React.lazy(
  () => import('modules/account/paymentTransactionDate')
);

const routes = {
  element: <PaymentTransactionDate />,
  title: 'Payment TXN Date',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: 'item',
  path: ['/account/payementdates'],
};

export default routes;
