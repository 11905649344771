import AuthRoutes from '@crema/components/AuthRoutes';
import CssBaseline from '@mui/material/CssBaseline';
import AppContextProvider from 'contexts';
import AppSettingContextProvider from 'contexts/appSetting';
import AppLayout from 'core/AppLayout';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './styles/index.css';
import '//unpkg.com/mathlive';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <AppSettingContextProvider>
          <AppContextProvider>
            <AuthRoutes>
              <CssBaseline />
              <RecoilRoot>
                <AppLayout />
              </RecoilRoot>
            </AuthRoutes>
          </AppContextProvider>
        </AppSettingContextProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
