import { FormControl, InputLabel, MenuItem } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const AppSelectField = (props) => {
  const {
    name,
    value,
    options,
    selectionKey,
    onChange,
    label,
    defaultValue = '',
    placeholder = '',
    disabled,
    required = true,
    valueSelectionKey = '_id',
    getValue = null,
    size = 'large',
    selectFieldSx = {},
    defaultSelectionOn = true,
    fullWidth = true,
  } = props;

  const [field, meta, helpers] = useField(props);

  const errorText = meta.error && meta.touched ? meta.error : '';

  useEffect(() => {
    if (defaultSelectionOn) {
      if (options?.length === 1 && !field?.value)
        helpers?.setValue(options?.[0]?._id);
    }
  }, [options?.length, defaultSelectionOn]);
  return (
    <FormControl
      sx={props?.sx ? props?.sx : null}
      fullWidth={fullWidth}
      required={required}
      size={size}
    >
      <InputLabel style={{ color: errorText && '#f44336' }} id={props?.id}>
        {label}
      </InputLabel>

      <Select
        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
        labelId={props?.id}
        name={name}
        label={label}
        {...field}
        onChange={(...rest) => {
          onChange?.(...rest);
          field.onChange(...rest);
        }}
        value={options?.length ? field?.value || defaultValue : ''}
        error={!!errorText}
        placeholder={label}
        disabled={disabled}
        sx={selectFieldSx}
      >
        {options
          ? options?.map((menu, index) => (
              <MenuItem
                sx={{
                  textTransform: 'none',
                }}
                key={index}
                value={menu?.[valueSelectionKey]}
              >
                {getValue ? getValue(menu) : menu[selectionKey]}
              </MenuItem>
            ))
          : props?.children}
      </Select>
      <FormHelperText style={{ color: '#f44336', margin: '3px 0 0 13px' }}>
        {errorText}
      </FormHelperText>
    </FormControl>
  );
};

export default AppSelectField;

AppSelectField.propTypes = {
  disabled: PropTypes.bool,
};
