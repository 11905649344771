import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const CompetitiveExamStudentApplications = React.lazy(
  () => import('modules/competitiveExam/competitiveExamStudentApplications')
);

const routes = {
  element: <CompetitiveExamStudentApplications />,
  title: 'Applications',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/competitiveexamstudentapplications'],
};

export default routes;
