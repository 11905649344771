import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const PermissionSetup = React.lazy(
  () => import('modules/master/system/permissionSetup')
);

const routes = {
  element: <PermissionSetup />,
  title: 'Permission Setup',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    '/permission/userroles',
    '/permission/modules',
    '/permission/routes',
    '/permission/permissions',
    '/permission/allocations',
  ],
};

export default routes;
