import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, Slide, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { CSS_CONSTANTS } from 'constants/AppConstant';
import { FONTS } from 'constants/AppEnum';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppDialog = ({
  sxStyle,
  maxWidth,
  hideClose,
  open,
  onClose,
  children,
  dividers,
  title,
  closeOnBody,
  actionTitle,
  maxScrollHeight,
  fullHeight,
  dialogIcon,
  contentStyle,
  fullScreen,
  overflowY = 'auto',
}) => {
  return (
    <Dialog
      fullScreen={fullScreen}
      sx={{
        // "& .MuiPaper-root": {
        //   overflowY: "none",
        // },
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: maxWidth,
          overflowY: 'unset',
        },
        '& .MuiDialogContent-root': {
          overflowY: overflowY,
          pb: 13,
        },
        overflow: 'none',
        zIndex: CSS_CONSTANTS.Z_INDEX.APP_DIALOG,
        ...sxStyle,
      }}
      TransitionComponent={Transition}
      open={open}
      onClose={closeOnBody ? onClose : null}
    >
      <Box
        sx={{
          fontWeight: FONTS.BOLD,
          p: 1,
          px: 5,
          fontSize: 16,
          display: 'flex',
          alignItems: 'center',
          textTransform: 'capitalize',
          '& svg': {
            mr: 1,
          },
        }}
        id="app-dialog-title"
      >
        {dialogIcon}
        <Typography p={2} mt={-0.2} sx={{ fontWeight: FONTS.BOLD }}>
          {title}
        </Typography>
        {hideClose ? null : (
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 4,
              top: 4,
              color: 'grey.700',
            }}
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      <DialogContent sx={contentStyle} dividers={dividers}>
        {children}
      </DialogContent>
    </Dialog>
  );
};
export default AppDialog;
