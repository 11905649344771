import aboutRoutes from './aboutRoutes';
import admissionRoutes from './admissionRoutes';
import contactUsRoutes from './contactUsRoutes';
import enquiryRoutes from './enquiryRoutes';
import homeRoutes from './homeRoutes';
import loginRoutes from './loginRoutes';
import nsat from './registerRoutes';

export default [
  homeRoutes,
  aboutRoutes,
  admissionRoutes,
  enquiryRoutes,
  nsat,
  loginRoutes,
  contactUsRoutes,
];
