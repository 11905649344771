import React from 'react';
import { USER_ROLE } from 'constants/AppConstant';

const AdmissionStudentAdmissionQueue = React.lazy(
  () => import('modules/student/admission/admissionStudentAdmissionQueue')
);

const routes = {
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  title: 'Admission Queue',
  path: ['/student/admissionqueue'],
  element: <AdmissionStudentAdmissionQueue />,
  type: 'item',
};

export default routes;
