import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const CorrectionQueue = React.lazy(
  () => import('modules/master/questionBank/correctionQueue')
);

const routes = {
  element: <CorrectionQueue />,
  title: 'Correction Queue',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/question/correctionqueue'],
};

export default routes;
