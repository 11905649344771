import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const SubjectSetup = React.lazy(
  () => import('modules/master/questionBank/subjectSetup')
);

const routes = {
  element: <SubjectSetup />,
  title: 'Syllabus Setup',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    '/subjectgroups',
    '/subjects/syllabusstatus',
    '/subjects',
    '/subjects/topics',
    '/subjects/divisiongroupwisetopicallocations',
    '/subjects/virtualdivisionsubjecttopics',
    '/subjects/divisiongroupwisetopicallocations/create',
    '/subjects/divisiongroupwisetopicallocations/update',
    '/subjects/topics/create',
    '/subjects/topics/update',
  ],
};

export default routes;
