import academicRoutes from './academic';
import communicationRoutes from './communication';
import questionBankRoutes from './questionBank';
import systemRoutes from './system';

export default [
  academicRoutes,
  systemRoutes,
  communicationRoutes,
  questionBankRoutes,
];
