import PropTypes from 'prop-types';
import CASProvider from './casContext';
import StudentAttendanceDeviceLogProvider from './studentAttendanceDeviceLogContext';
import StudentAttendanceReportProvider from './studentAttendanceReportContext';
import StudentManualAttendanceProvider from './studentManualAttendanceContext';
import UserAttendanceLogProvider from './userAttendanceLogContext';
import UserExtraWorkingScheduleProvider from './userExtraWorkingScheduleContext';
import UserShiftWiseAttendanceLogProvider from './userShiftWiseAttendanceLogContext';

const AttendanceProvider = ({ children }) => {
  return (
    <CASProvider>
      <UserExtraWorkingScheduleProvider>
        <UserShiftWiseAttendanceLogProvider>
          <UserAttendanceLogProvider>
            <StudentAttendanceReportProvider>
              <StudentManualAttendanceProvider>
                <StudentAttendanceDeviceLogProvider>
                  {children}
                </StudentAttendanceDeviceLogProvider>
              </StudentManualAttendanceProvider>
            </StudentAttendanceReportProvider>
          </UserAttendanceLogProvider>
        </UserShiftWiseAttendanceLogProvider>
      </UserExtraWorkingScheduleProvider>
    </CASProvider>
  );
};

export default AttendanceProvider;

AttendanceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
