import PropTypes from 'prop-types';
import LiveClassScheduleProvider from './liveClassScheduleContext';
import LiveClassSignatureProvider from './liveClassSignatureContext';
import LiveClassTypeProvider from './liveClassTypeContext';

const LiveClassProvider = ({ children }) => {
  return (
    <LiveClassTypeProvider>
      <LiveClassScheduleProvider>
        <LiveClassSignatureProvider>{children}</LiveClassSignatureProvider>
      </LiveClassScheduleProvider>
    </LiveClassTypeProvider>
  );
};

export default LiveClassProvider;

LiveClassProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
