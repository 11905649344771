// dispatch types is constant object for dispatch types in reducer
import { DISPATCH_TYPES } from 'constants/ActionTypes';
export const SET_STUDENT_ID = 'SET_STUDENT_ID';
export const SET_STUDENT_ID_CARD_DATA = 'SET_STUDENT_ID_CARD_DATA';

const reducer = (state, action) => {
  switch (action.type) {

    case "ROLL_NUMBER_LIST":
      return {
          ...state,
          rollNumberList: action.payload.data,
      }
      case "UPDATE_ROLL_NUMBER_LIST":
        return {
            ...state,
            rollNumberList: action.payload,
        }
    // It will set the student id
    case SET_STUDENT_ID:
      return {
        ...state,
        studentId: action.payload,
      };

    // It will set all fetched records in state
    case DISPATCH_TYPES.GET_ALL:
      return {
        data: action.payload.data,
        count: action.payload.count,
      };

    // It will set all fetched records in state
    case DISPATCH_TYPES.EXPORT:
      return {
        ...state,
        exportList: action.payload.data,
      };

    // It will set all fetched records in state
    case DISPATCH_TYPES.GET:
      return {
        ...state,
        item: action.payload.data,
      };

    // It will set state for created record
    case DISPATCH_TYPES.CREATE:
      return {
        data: [...state.data, action.payload],
        count: state.count + 1,
      };

    // It will set state for deleted records
    case DISPATCH_TYPES.DELETE:
      return {
        data: state.data.filter(
          (dataItem) => dataItem.student_admission_id !== action.payload.data
        ),
        count: state.count - 1,
      };

    // It will set state for updated record
    case DISPATCH_TYPES.UPDATE:
      for (let i = 0; i < state.data.length; i++) {
        if (state.data[i]._id === action.payload._id) {
          state.data[i] = action.payload;
          break;
        }
      }
      return state;

    // If no case matches then return same state
    default:
      return state;
  }
};

export default reducer;
