import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const AssignmentSummary = React.lazy(
  () => import('modules/assignment/assignmentSummary')
);

const routes = {
  element: <AssignmentSummary />,
  title: 'Assignment Summary',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/assignmentsummary'],
};

export default routes;
