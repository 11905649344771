import jwtAxios from '@crema/services/auth/jwt-auth';
import { DISPATCH_TYPES } from 'constants/ActionTypes';
import { useNotificationActionsContext } from 'contexts/common/notificationContext';

const ApiHelper = () => {
  const { fetchStart, fetchError, fetchSuccess, showMessage } =
    useNotificationActionsContext();

  const GetListAPI = (
    apiName,
    dispatch,
    params,
    callbackFunction = null,
    onError = null,
    dispatchType = DISPATCH_TYPES.GET_ALL
  ) => {
    const controller = new AbortController();
    fetchStart();
    jwtAxios
      .get(apiName, {
        params: params,
        signal: controller.signal,
      })
      .then((data) => {
        if (data.status === 200) {
          fetchSuccess();

          data?.data?.message && showMessage(data?.data?.message);

          callbackFunction && callbackFunction(data.data.data, data.data.count);
          dispatch &&
            dispatch({
              type: dispatchType,
              payload: {
                data: data.data.data,
                count: data.data.count,
              },
            });
        } else {
          fetchError('something went wrong!');
        }
      })
      .catch((error) => {
        if (error?.message !== 'canceled') {
          fetchError(error?.response?.data?.message);
        }
        onError && onError(error);
      });
    return controller;
  };

  const GetAPI = (
    apiName,
    dispatch,
    _id = '',
    params = {},
    callbackFunction = null,
    onError = null
  ) => {
    fetchStart();
    jwtAxios
      .get(`${apiName}/${_id}`, {
        params: params,
      })
      .then((data) => {
        if (data.status === 200) {
          fetchSuccess();
          callbackFunction && callbackFunction(data.data?.data);
          dispatch({
            type: DISPATCH_TYPES.GET,
            payload: { data: data.data?.data },
          });
        } else {
          fetchError('something went wrong!');
        }
      })
      .catch((error) => {
        fetchError(error?.response?.data?.message);
        onError && onError(error);
      });
  };

  const DeleteAPI = (
    apiName,
    dispatch,
    params,
    _ids,
    message = '',
    onSuccess,
    onError
  ) => {
    fetchStart();
    jwtAxios
      .patch(
        apiName,
        {
          formData: {
            _ids: _ids, // req body
          },
        },
        {
          params: params,
        }
      )
      .then((data) => {
        if (data.status === 200) {
          fetchSuccess();
          dispatch({
            type: DISPATCH_TYPES.DELETE,
            payload: {
              data: data.data.data,
              count: data.data.count,
              _ids: _ids,
            },
          });

          onSuccess &&
            onSuccess({
              data: data.data.data,
              count: data.data.count,
            });

          showMessage(message || 'Record deleted successfully...');
        } else {
          fetchError('something went wrong!');
        }
      })
      .catch((error) => {
        onError && onError(error);
        fetchError(error?.response?.data?.message);
      });
  };

  const UpdateAPI = (
    apiName,
    dispatch,
    formData,
    setSubmitting,
    resetForm,
    handleAddClose,
    message = '',
    callbackFunction,
    dispatchType = DISPATCH_TYPES.UPDATE
  ) => {
    fetchStart();
    setSubmitting && setSubmitting(true);
    jwtAxios
      .put(apiName, { formData })
      .then((data) => {
        if (data.status === 200) {
          fetchSuccess();
          dispatch({
            type: dispatchType,
            payload: data.data.data,
          });
          resetForm && resetForm();
          handleAddClose && handleAddClose();
          callbackFunction && callbackFunction(data.data.data);
          showMessage(
            message || data?.data?.message || 'Record updated successfully...'
          );
        } else {
          fetchError('something went wrong!');
        }
      })
      .catch((error) => {
        fetchError(error?.response?.data?.message);
      })
      .finally(() => {
        setSubmitting && setSubmitting(false);
      });
  };

  const CreateAPI = (
    apiName,
    dispatch,
    formData,
    setSubmitting = null,
    resetForm = null,
    handleAddClose = null,
    message = '',
    callbackFunction,
    onError,
    dispatchType = DISPATCH_TYPES.CREATE
  ) => {
    delete formData._id;
    fetchStart();
    setSubmitting && setSubmitting(true);
    jwtAxios
      .post(apiName, { formData })
      .then((data) => {
        if (data.status === 200) {
          callbackFunction && callbackFunction(data.data.data);
          dispatch && dispatch({ type: dispatchType, payload: data.data.data });
          resetForm && resetForm();
          handleAddClose && handleAddClose();
          fetchSuccess();
          showMessage(
            message || data?.data?.message || 'Record created successfully...'
          );
        } else {
          fetchError('something went wrong!');
        }
      })
      .catch((error) => {
        fetchError(error?.response?.data?.message);
        onError && onError(error?.response?.data);
        setSubmitting && setSubmitting(false);
      })
      .finally(() => {
        setSubmitting && setSubmitting(false);
      });
  };

  return { GetListAPI, GetAPI, CreateAPI, UpdateAPI, DeleteAPI };
};

export default ApiHelper;
