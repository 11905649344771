import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const UserShiftWiseAttendanceLog = React.lazy(
  () => import('modules/attendance/userShiftWiseAttendanceLogs')
);

const routes = {
  element: <UserShiftWiseAttendanceLog />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/attendance/usershiftwiseattendancelogs'],
  title: 'User Attendance',
  type: 'item',
};

export default routes;
