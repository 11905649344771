// dispatch types is constant object for dispatch types in reducer
import { DISPATCH_TYPES } from 'constants/ActionTypes';

const reducer = (state, action) => {
  switch (action.type) {
    // It will set all fetched records in state
    case DISPATCH_TYPES.GET_ALL:
      return {
        data: action.payload.data,
        count: action.payload.count,
      };

    // If no case matches then return same state
    default:
      return state;
  }
};

export default reducer;
