import PropTypes from 'prop-types';
import CompetitiveExamProvider from './competitiveExamContext';
import CompetitiveExamScheduleProvider from './competitiveExamScheduleContext';
import CompetitiveExamStudentApplicationProvider from './competitiveExamStudentApplicationContext';

const CompetitiveExamsProvider = ({ children }) => {
  return (
    <CompetitiveExamProvider>
      <CompetitiveExamScheduleProvider>
        <CompetitiveExamStudentApplicationProvider>
          {children}
        </CompetitiveExamStudentApplicationProvider>
      </CompetitiveExamScheduleProvider>
    </CompetitiveExamProvider>
  );
};

export default CompetitiveExamsProvider;

CompetitiveExamsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
