import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const ExamSetup = React.lazy(() => import('modules/exam/examSetup'));

const routes = {
  element: <ExamSetup />,
  title: 'Exam Setup',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    '/exam/schedules',
    '/exam/grades',
    '/exam/terms',
    '/exam/groups',
    '/exam/types',
    '/exam/patterns',
  ],
};

export default routes;
