import { USER_ROLE } from 'constants/AppConstant';
import payRollSetupRoutes from './payRollSetupRoutes';
import userPayrollRoutes from './userPayrollRoutes';

import { TbReceiptRupee } from 'react-icons/tb';

export default {
  id: 'Payroll',
  title: 'Payroll',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: 'collapse',
  icon: <TbReceiptRupee />,
  path: ['/payrollMenu'],

  children: [payRollSetupRoutes, userPayrollRoutes],
};
