import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const StudentAttendanceDeviceReport = React.lazy(
  () => import('modules/attendance/studentAttendanceReport')
);

const routes = {
  element: <StudentAttendanceDeviceReport />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/attendance/studentattendancedevicereports'],
  title: 'Student ATD Report',
  type: 'item',
};

export default routes;
