import React from 'react';
import { USER_ROLE } from 'constants/AppConstant';

const WhatsappSetup = React.lazy(
  () => import('modules/master/communication/whatsappSetup')
);

const routes = {
  element: <WhatsappSetup />,
  title: 'Whatsapp Setup',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/whatsapp/gateways', '/whatsapp/templates'],
};

export default routes;
