import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

const AppGridContainer = ({ children, ...others }) => {
  const isMDDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <Grid container spacing={isMDDown ? 3 : 5} {...others}>
      {children}
    </Grid>
  );
};

export default AppGridContainer;

AppGridContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
