import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const PaymentTransactionSummaryByDivision = React.lazy(
  () => import('modules/account/paymentTransactionSummaryByDivision')
);

const routes = {
  element: <PaymentTransactionSummaryByDivision />,
  title: 'Overall Payment Summary',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: 'item',
  path: ['/account/paymenttransactionsummarybydivision'],
};

export default routes;
