import { SYSTEM_CONSTANTS, USER_ROLE } from 'constants/AppConstant';

const PermissionHelper = (userRole, permissions, route) => {
  let hasPermission = false;

  switch (userRole) {
    case USER_ROLE.SUPER_ADMIN:
      hasPermission = route.permittedRole?.includes(USER_ROLE.SUPER_ADMIN);
      break;
    case USER_ROLE.PUBLIC:
      hasPermission = route.permittedRole?.includes(USER_ROLE.PUBLIC);
      break;
    default:
      hasPermission =
        route?.path?.find(
          (p) => permissions?.[p]?.[SYSTEM_CONSTANTS.PERMISSIONS_OBJ.GET_LIST]
        ) || false;
  }

  route.hasPermission = hasPermission;
  route.permittedRole = hasPermission ? userRole : route?.permittedRole;

  return route;
};

export const isRolesGreaterThanBranchHead = (role_name) =>
  [USER_ROLE.SUPER_ADMIN, USER_ROLE.DIRECTOR, USER_ROLE.BRANCH_HEAD].includes(
    role_name
  );
export default PermissionHelper;
