import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const ExamReportCard = React.lazy(() => import('modules/exam/examReportCard'));

const routes = {
  element: <ExamReportCard />,
  title: 'Generate Report Card',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/examreportcards'],
};

export default routes;
