import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const Testing = React.lazy(() => import('modules/Testing'));

const routes = [
  {
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: ['/testing'],
    element: <Testing />,
  },
];

export default routes;
