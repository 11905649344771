// React Utilities
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Crema Componenets
import AppDialog from '@crema/components/AppDialog';

// MUI icons
import GavelIcon from '@mui/icons-material/Gavel';
import { renderList } from 'helpers/ComponentHelper';
import { Button } from '@mui/material';

const TermsAndConditions = ({
  isTermsAndConditionsOpen,
  setIsTermsAndConditionsOpen,
}) => {
  // It will handle the click on the close button of dialog box
  const handleClose = useCallback(() => setIsTermsAndConditionsOpen(false), []);

  return (
    <AppDialog
      dividers
      dialogIcon={<GavelIcon />}
      title={
        <>
          Terms & Conditions <br /> I hereby affirm and declare:
        </>
      }
      onClose={handleClose} // It will close the dialog box
      open={isTermsAndConditionsOpen}
    >
      {renderList(
        [
          <>
            That I took admission for the school program at
            <strong> Netizens Foundation School</strong> voluntarily, out of my
            own interest and accord.
          </>,
          <>
            That all information furnished by me in the OMR application form is
            correct to the best of my knowledge. In the event of any information
            found to be incorrect or false, my admission may be cancelled
            without any refund of fee paid
          </>,
          <>
            That I have read and understood, and shall obey in word and spirit,
            all applicable guidelines, policies, rules and regulations of
            <strong> Netizens Foundation School</strong> (as may be amended from
            time to time).
          </>,
          <>
            That I shall be solely responsible in case of my rustication from
            <strong> Netizens Foundation School</strong> and / or termination of
            my enrollment by <strong> Netizens Foundation School</strong>{' '}
            including the initiation of any legal actions by Netizens Foundation
            School, on grounds of breach of applicable guidelines, policies,
            rules and regulations and / or any misconduct or illegal activities,
            whether on or off-campus including at any student accommodation
            facilities.
          </>,
          <>
            That if I am absent from classes for a period of 30 (thirty) days in
            continuity without any notice to
            <strong> Netizens Foundation School</strong>,
            <strong> Netizens Foundation School</strong> retains the right to
            cancel my admission and will be discharged from all responsibilities
            and liabilities towards me, without any obligation for a refund of
            course fee.
          </>,
          <>
            That in case of my selection in the medical / engineering entrance
            exams, national or international Olympiads, National Talent Search
            Exam (NTSE), Kishore Vaigyanik Protsahan Yojana (KVPY) exam, and /
            or any other examination,
            <strong> Netizens Foundation School</strong> reserves its exclusive
            right to use my information for its marketing and publicity
            purposes.
          </>,
          <>
            That I authorize <strong> Netizens Foundation School</strong> to
            contact me or my parents / guardian through phone calls, automated
            phone calls, emails, SMS, WhatsApp and / or push notifications
            alerts.
          </>,
          <>
            That any unauthorized downloading or uploading of videos - whether
            on campus or at student accommodation facilities, taking
            screenshots, recording lecture videos, broadcasting of live /
            recorded classes on open / public forum, sharing study materials,
            test papers, or any other information that has been provided by
            <strong> Netizens Foundation School</strong> to me, is strictly
            prohibited.
          </>,
          <>
            That I understand(s) that the study materials, contents, notes
            provided by
            <strong> Netizens Foundation School</strong> is solely Netizens
            Foundation School&apos;s intellectual property.
          </>,
          <>
            That I have read all the rules (including the refund rules) and
            regulations of <strong> Netizens Foundation School</strong>&apos;s
            coaching centers.
          </>,
          <>
            That I have read all the details set out in the application kit and
            I do agree to follow the instructions as applicable.
          </>,
        ],
        'ol',
        'number'
      )}
      <Button
        variant="contained"
        sx={{
          float: 'right',
        }}
        onClick={handleClose}
      >
        Close
      </Button>
    </AppDialog>
  );
};

export default React.memo(TermsAndConditions);

TermsAndConditions.propTypes = {
  isTermsAndConditionsOpen: PropTypes.bool,
  setIsTermsAndConditionsOpen: PropTypes.func,
};
