import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const AdmissionStudentAdmissions = React.lazy(
  () => import('modules/student/admission/admissionStudentAdmissions')
);

const routes = {
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/student/admissions'],
  element: <AdmissionStudentAdmissions />,
  title: 'Admission List',
  type: 'item',
};

export default routes;
