import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const PayRollSetup = React.lazy(() => import('modules/payRoll/payRollSetup'));

const routes = {
  element: <PayRollSetup />,
  title: 'Payroll Setup',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/payroll/statutorycomponents', '/payroll/salarypaymentmethods'],
};

export default routes;
