import { useAuthUser } from '@crema/hooks/AuthHooks';
import { useEffect, useMemo, useState } from 'react';

// React imports
import { createContext, useContext } from 'react';

// context to be used in the app
const DefaultFilterContext = createContext();

// hook to use the context states in the ui
const useDefaultFilterContext = () => {
  return useContext(DefaultFilterContext);
};
export { useDefaultFilterContext };

// provider of the context
const DefaultFilterProvider = ({ children }) => {
  const { user } = useAuthUser();

  // Initial State
  const [defaultFilters, setDefaultFilters] = useState({
    branch_id: user?.default_branch_id ? user?.default_branch_id : '',
    academic_year_id: user?.default_academic_year_id
      ? user?.default_academic_year_id
      : '',
    branch_city_id: user?.branch_city_id ? user?.branch_city_id : '',
    branch_type: user?.default_branch_type ? user?.default_branch_type : '',
  });

  const [defaultMappings, setDefaultMappings] = useState({
    branches: user?.branches,
    branchCities: user?.branchCities,
  });

  const updateDefaultFilters = (newFilters) => {
    setDefaultFilters((prev) => ({ ...prev, ...newFilters }));
  };

  const defaultFiltersMemo = useMemo(
    () => ({
      branch_id: defaultFilters?.branch_id,
      branch_city_id: defaultFilters?.branch_city_id,
      academic_year_id: defaultFilters?.academic_year_id,
    }),
    [
      defaultFilters?.branch_id,
      defaultFilters?.branch_city_id,
      defaultFilters?.academic_year_id,
    ]
  );

  useEffect(() => {
    setDefaultMappings({
      branches: user?.branches,
      branchCities: user?.branchCities,
    });
    updateDefaultFilters({
      branch_id: user?.default_branch_id ? user?.default_branch_id : '',
      academic_year_id: user?.default_academic_year_id
        ? user?.default_academic_year_id
        : '',
      branch_city_id: user?.branch_city_id ? user?.branch_city_id : '',
      branch_type: user?.default_branch_type ? user?.default_branch_type : '',
    });
  }, [
    user?.branches,
    user?.branchCities,
    user?.default_branch_type,
    user?.default_academic_year_id,
    user?.default_branch_id,
  ]);

  return (
    <DefaultFilterContext.Provider
      value={{
        defaultFilters: defaultFilters,
        setDefaultFilters: setDefaultFilters,
        updateDefaultFilters,
        defaultMappings,
        defaultFiltersMemo,
      }}
    >
      {children}
    </DefaultFilterContext.Provider>
  );
};

export default DefaultFilterProvider;
