import { USER_ROLE } from 'constants/AppConstant';
import { FaClipboardQuestion } from 'react-icons/fa6';
import correctionQueueRoutes from './correctionQuestionRoutes';
import questionSetupRoutes from './questionSetupRoutes';
import verifyQuestionRoutes from './verifyQuestionRoutes';

const questionBankConfig = {
  id: 'Question Bank',
  title: 'Question Bank',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: 'collapse',
  icon: <FaClipboardQuestion />,
  path: ['/questionBankMenu'],

  children: [questionSetupRoutes, verifyQuestionRoutes, correctionQueueRoutes],
};

export default questionBankConfig;
