import PropTypes from 'prop-types';
import LeaveReasonProvider from './leaveReasonContext';
import LeaveTypeProvider from './leaveTypeContext';
import PasswordProvider from './passwordContext';
import UserBankAccountProvider from './userBankAccountContext';
import UserBranchMappingProvider from './userBranchMappingContext';
import UserEducationProvider from './userEducationContext';
import UserLeaveAllocationProvider from './userLeaveAllocationContext';
import UserLeaveProvider from './userLeaveContext';
import UserLeaveRelieverProvider from './userLeaveRelieverContext';
import UserRegistrationProvider from './userRegistrationContext';
import UserRegularWorkingScheduleProvider from './userRegularWorkingScheduleContext';

const UserContextProvider = ({ children }) => {
  return (
    <UserLeaveRelieverProvider>
      <UserLeaveProvider>
        <UserRegularWorkingScheduleProvider>
          <UserLeaveAllocationProvider>
            <LeaveTypeProvider>
              <LeaveReasonProvider>
                <PasswordProvider>
                  <UserRegistrationProvider>
                    <UserBankAccountProvider>
                      <UserEducationProvider>
                        <UserBranchMappingProvider>
                          {children}
                        </UserBranchMappingProvider>
                      </UserEducationProvider>
                    </UserBankAccountProvider>
                  </UserRegistrationProvider>
                </PasswordProvider>
              </LeaveReasonProvider>
            </LeaveTypeProvider>
          </UserLeaveAllocationProvider>
        </UserRegularWorkingScheduleProvider>
      </UserLeaveProvider>
    </UserLeaveRelieverProvider>
  );
};

export default UserContextProvider;

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
