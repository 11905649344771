// React imports
import { createContext, useCallback, useContext, useReducer } from 'react';

// Generic API helper
import ApiHelper from 'helpers/ApiHelper';

// Constant API names and default api params
import { API_NAMES } from 'constants/ApiConstants';

// Reducer for changing state
import reducer from 'reducers/admin/dashboard/paymentTransactionSummaryByMonthReducer';

// context to be used in the app
const PaymentTransactionSummaryByMonthContext = createContext();

// hook to use the context states in the ui
const usePaymentTransactionSummaryByMonthContext = () => {
  return useContext(PaymentTransactionSummaryByMonthContext);
};
export { usePaymentTransactionSummaryByMonthContext };

// Initial State
const initialState = {
  data: [],
};

// provider of the context
const PaymentTransactionSummaryByMonthProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { GetListAPI } = ApiHelper();

  // Reducer to set state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Name of the api to hit
  const apiName =
    API_NAMES.ADMIN.DASHBOARD.PAYMENT_TRANSACTION_SUMMARY_BY_MONTH;

  // Fetches whole records from DB
  const getList = useCallback((params, callbackFunction, a, onError) => {
    return GetListAPI(apiName, dispatch, params, callbackFunction, onError);
  }, []);

  return (
    <PaymentTransactionSummaryByMonthContext.Provider
      value={{
        data: state.data,
        getList,
      }}
    >
      {children}
    </PaymentTransactionSummaryByMonthContext.Provider>
  );
};

export default PaymentTransactionSummaryByMonthProvider;
