import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import { USER_ROLE } from 'constants/AppConstant';
import attendanceSetupRoutes from './attendanceSetupRoutes';
import smsSetupRoutes from './smsSetupRoutes';
import whatsappSetupRoutes from './whatsappSetupRoutes';
import zoomSetupRoutes from './zoomSetupRoutes';

const communicationConfig = {
  id: 'Communication',
  title: 'Communication',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: 'collapse',
  icon: <SatelliteAltIcon />,
  path: ['/communicationMenu'],

  children: [
    smsSetupRoutes,
    whatsappSetupRoutes,
    attendanceSetupRoutes,
    zoomSetupRoutes,
  ],
};

export default communicationConfig;
