import { NOTIFICATION_MESSAGES } from 'constants/ActionTypes';

export const contextReducer = (state, action) => {
  switch (action.type) {
    case NOTIFICATION_MESSAGES.FETCH_START: {
      return {
        ...state,
        loading: true,
        message: '',
        error: '',
      };
    }
    case NOTIFICATION_MESSAGES.FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        message: '',
        error: '',
      };
    }
    case NOTIFICATION_MESSAGES.SHOW_MESSAGE: {
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: '',
      };
    }
    case NOTIFICATION_MESSAGES.FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        message: '',
        error: action.payload,
        currentTime: Date.now(),
      };
    }
    case NOTIFICATION_MESSAGES.HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        message: '',
        error: '',
      };
    }
    default:
      return state;
  }
};
