import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const AccountSummary = React.lazy(
  () => import('modules/account/accountSummary')
);

const routes = {
  element: <AccountSummary />,
  title: 'Concession Summary',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: 'item',
  path: ['/account/concessionsummary'],
};

export default routes;
