import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';
import { BsFillClipboardFill } from 'react-icons/bs';

const AdmissionStudentAssignment = React.lazy(
  () => import('modules/student/admission/admissionStudentAssignment')
);

const routes = {
  permittedRole: [USER_ROLE.STUDENT],
  title: 'Assignments',
  path: ['/student/assignments'],
  element: <AdmissionStudentAssignment />,
  type: 'item',
  icon: <BsFillClipboardFill />,
};

export default routes;
