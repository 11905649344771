import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const HolidaySetup = React.lazy(
  () => import('modules/master/system/holidaySetup')
);

const routes = {
  element: <HolidaySetup />,
  title: 'Holiday Setup',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/masholidays', '/holidays'],
};

export default routes;
