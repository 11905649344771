import { Box } from '@mui/material';
import appLogo from 'assets/public/app-logo.png';
import { useAppSettingContext } from 'contexts/appSetting';
import { Link } from 'react-router-dom';

const AppLogo = ({ sx }) => {
  const { appSetting } = useAppSettingContext();
  const styles = {
    height: { xs: 56, sm: 70, lg: 87 },
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    ml: 3,
    '& img': {
      height: '100%',
    },
  };
  return (
    <Link to={appSetting?.initialURL}>
      <Box sx={sx ? { ...styles, ...sx } : styles} className="app-logo">
        <img srcSet={appLogo} src={appLogo} alt="logo" loading="lazy" />
      </Box>
    </Link>
  );
};

export default AppLogo;
