import { USER_ROLE } from 'constants/AppConstant';
import { MdLiveTv } from 'react-icons/md';
import liveClassScheduleRoutes from './liveClassSetupRoutes';

const menu = {
  id: 'Live Class',
  title: 'Live Class',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: 'collapse',
  icon: <MdLiveTv />,
  path: ['/liveClassMenu'],
  children: [liveClassScheduleRoutes],
};

export default menu;
