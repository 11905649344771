import { Grid } from '@mui/material';

const WithGridItem = (props) => {
  return (
    <Grid
      xs={12}
      item
      {...props}
      sx={{
        position: 'relative',
        ...props?.sx,
      }}
    >
      {props?.children}
    </Grid>
  );
};

export default WithGridItem;
