import admissionStudentAssignmentRoutes from './admissionStudentAssignmentRoutes';
import admissionStudentExamReportRoutes from './admissionStudentExamReportRoutes';
import admissionStudentLiveClassRoutes from './admissionStudentLiveClassRoutes';
import admissionStudentMyExamRoute from './admissionStudentMyExamRoute';
import admissionStudentReceiptListRoutes from './admissionStudentReceiptListRoutes';
import admissionStudentStudyMaterialRoutes from './admissionStudentStudyMaterialRoutes';

export default [
  admissionStudentStudyMaterialRoutes,
  admissionStudentLiveClassRoutes,
  admissionStudentAssignmentRoutes,
  admissionStudentMyExamRoute,
  admissionStudentExamReportRoutes,
  admissionStudentReceiptListRoutes,
];
