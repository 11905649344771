// React imports
import { createContext, useContext, useReducer } from 'react';

// Generic API helper
import ApiHelper from 'helpers/ApiHelper';

// Constant API names and default api params
import { API_NAMES } from 'constants/ApiConstants';

// Reducer for changing state
import reducer, {
  CLEAR_LAST_REGISTRATION,
  LAST_REGISTRATION,
} from 'reducers/student/studentRegistrationReducer';

// Helper to convert text into uppercase
import getUpperCase from 'helpers/UpperCaseHelper';

// Custom hook for notifications
import { useNotificationActionsContext } from 'contexts/common/notificationContext';

// Constants for the student
import { STUDENT_CONSTANT } from 'constants/AppConstant';

// AXIOS Object to hit api
import jwtAxios from '@crema/services/auth/jwt-auth';

// context to be used in the app
const StudentRegistrationContext = createContext();

// hook to use the context states in the ui
const useStudentRegistrationContext = () => {
  return useContext(StudentRegistrationContext);
};
export { useStudentRegistrationContext };

// Initial StudentRegistration
const initialStudentRegistration = {
  data: [],
  lastRegistration: {},
  count: 0,
  item: {},
};

// provider of the context
const StudentRegistrationProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { GetListAPI, GetAPI, UpdateAPI, CreateAPI, DeleteAPI } = ApiHelper();
  const { fetchStart, fetchError, fetchSuccess, showMessage } =
    useNotificationActionsContext();

  // Reducer to set state
  const [state, dispatch] = useReducer(reducer, initialStudentRegistration);

  // Name of the api to hit
  const apiName = API_NAMES.STUDENT.STUDENT_REGISTRATION;

  // Fetches whole records from DB
  const getList = (params, callbackFunction) => {
    return GetListAPI(apiName, dispatch, params, callbackFunction);
  };

  // Fetches single record in records of DB
  const getItem = (_id, params, onSuccess, onError) => {
    GetAPI(apiName, dispatch, _id, params, onSuccess, onError);
  };

  // Update single record in records of DB
  const updateItem = (formData, setSubmitting, resetForm, handleAddClose) => {
    UpdateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose
    );
  };

  // Add new record in records of DB

  const clearLastRegistration = () => {
    dispatch({
      type: CLEAR_LAST_REGISTRATION,
    });
  };

  const createItem = (
    formData,
    setSubmitting,
    resetForm,
    admission_mode_name = STUDENT_CONSTANT.ADMISSION_MODE.ENTRANCE,
    handleAddClose = () => {}
  ) => {
    // converts this fields into uppercase
    formData.first_name = getUpperCase(formData?.first_name);
    formData.father_name = getUpperCase(formData?.father_name);
    formData.last_name = getUpperCase(formData?.last_name);
    formData.admission_mode_name = formData?.admission_mode_name
      ? formData.admission_mode_name
      : admission_mode_name;

    // Delete the extra field in form
    delete formData?.is_agree;
    delete formData?.is_authorize;

    fetchStart();
    setSubmitting(true);

    return new Promise((resolve, reject) => {
      jwtAxios
        .post(apiName, { formData })
        .then((data) => {
          if (data.status === 200) {
            dispatch({
              type: LAST_REGISTRATION,
              payload: data.data.data,
            });
            fetchSuccess();
            resetForm();
            handleAddClose();
            showMessage('Registration Successful...');
            resolve();
          } else {
            fetchError('something went wrong!');
          }
        })
        .catch((error) => {
          reject();
          fetchError(error?.response?.data?.message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  // Delete on or may records from records in DB
  const deleteItems = (params, _ids, onSuccess, onError) => {
    DeleteAPI(apiName, dispatch, params, _ids, '', onSuccess, onError);
  };

  return (
    <StudentRegistrationContext.Provider
      value={{
        data: state.data,
        count: state.count,
        lastRegistration: state.lastRegistration,
        item: state.item,
        getList,
        getItem,
        updateItem,
        createItem,
        deleteItems,
        clearLastRegistration,
      }}
    >
      {children}
    </StudentRegistrationContext.Provider>
  );
};

export default StudentRegistrationProvider;
