// React imports
import { createContext, useContext, useReducer } from 'react';

// Generic API helper
import ApiHelper from 'helpers/ApiHelper';

// Constant API names and default api params
import { API_NAMES } from 'constants/ApiConstants';

// Reducer for changing state
import { DISPATCH_TYPES } from 'constants/ActionTypes';
import reducer from 'reducers/attendance/studentAttendanceReportReducer';

// context to be used in the app
const StudentAttendanceReportContext = createContext();

// hook to use the context states in the ui
const useStudentAttendanceReportContext = () => {
  return useContext(StudentAttendanceReportContext);
};
export { useStudentAttendanceReportContext };

// Initial State
const initialState = {
  data: [],
  count: 0,
  printList: [],
};

// provider of the context
const StudentAttendanceReportProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { GetListAPI } = ApiHelper();

  // Reducer to set state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Name of the api to hit
  const apiName = API_NAMES.ATTENDANCE.STUDENT_ATTENDANCE_REPORT;

  // Fetches whole records from DB
  const getList = (params, callbackFunction) => {
    return GetListAPI(apiName, dispatch, params, callbackFunction);
  };

  // Fetches whole records from DB
  const getPrintList = (params, callbackFunction) => {
    return GetListAPI(
      apiName,
      dispatch,
      params,
      callbackFunction,
      null,
      DISPATCH_TYPES.PRINT
    );
  };

  return (
    <StudentAttendanceReportContext.Provider
      value={{
        data: state.data,
        printList: state.printList,
        count: state.count,
        getList,
        getPrintList,
      }}
    >
      {children}
    </StudentAttendanceReportContext.Provider>
  );
};

export default StudentAttendanceReportProvider;
