import { useAuthUser } from '@crema/hooks/AuthHooks';
import { Icon, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { SYSTEM_CONSTANTS } from 'constants/AppConstant';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppBadge from '../../../../AppBadge';
import AppNavLink from '../../../../AppNavLink';
import VerticalNavItem from './VerticalNavItem';

const VerticalItem = ({ level, item }) => {
  const { pathname } = useLocation();
  const { permissions } = useAuthUser();
  // State To highlight active path in the navigation panel
  let [pathFromArray, setPathFromArray] = useState('');

  useEffect(() => {
    // Checking the path property is array or not
    if (Array.isArray(item.path)) {
      // Setting the path in path object that matches with pathname in address bar
      setPathFromArray(item.path.filter((u) => u === pathname)?.[0]);
      if (!pathFromArray) return;
      setTimeout(() => {
        document
          .getElementById(pathname)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 1);
    } else {
      if (pathname === item.path && document.getElementById(pathname)) {
        setTimeout(() => {
          document
            .getElementById(pathname)
            ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 1);
      }
    }
  }, [pathname]);

  if (!item?.hasPermission) {
    return null;
  }

  return (
    <VerticalNavItem
      level={level}
      button
      id={
        Array.isArray(item.path)
          ? pathFromArray
            ? pathFromArray
            : item.path?.find(
                (path) =>
                  permissions?.[path]?.[
                    SYSTEM_CONSTANTS.PERMISSIONS_OBJ.GET_LIST
                  ]
              ) || item.path[0]
          : item.path
      }
      component={AppNavLink}
      to={
        Array.isArray(item.path)
          ? pathFromArray
            ? pathFromArray
            : item.path?.find(
                (path) =>
                  permissions?.[path]?.[
                    SYSTEM_CONSTANTS.PERMISSIONS_OBJ.GET_LIST
                  ]
              ) || item.path[0]
          : item.path
      }
      activeClassName="active"
      exact={item.exact}
    >
      {item.icon && (
        <Box component="span">
          <Icon
            sx={{
              fontSize: 18,
              display: 'block',
              mr: 4,
            }}
            className={clsx('nav-item-icon', 'material-icons-outlined')}
            color="action"
          >
            {item.icon}
          </Icon>
        </Box>
      )}
      <ListItemText
        className="nav-item-content"
        primary={item.title}
        classes={{ primary: 'nav-item-text' }}
      />
      {item.count && (
        <Box sx={{ mr: 3.5 }} className="menu-badge">
          <AppBadge count={item.count} color={item.color} />
        </Box>
      )}
    </VerticalNavItem>
  );
};

export default React.memo(VerticalItem);
