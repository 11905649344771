import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const StudentAttendanceDeviceLogs = React.lazy(
  () => import('modules/attendance/studentAttendanceLogs')
);

const routes = {
  element: <StudentAttendanceDeviceLogs />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/attendance/studentattendancedevicelogs'],
  title: 'Student ATD Logs',
  type: 'item',
};

export default routes;
