// React imports
import { createContext, useCallback, useContext, useReducer } from 'react';

// Generic API helper
import ApiHelper from 'helpers/ApiHelper';

// Constant API names and default api params
import { API_NAMES } from 'constants/ApiConstants';

// Reducer for changing state
import reducer from 'reducers/admin/dashboard/studentFeesConcessionByDivisionReducer';

// context to be used in the app
const StudentFeesConcessionByDivisionContext = createContext();

// hook to use the context states in the ui
const useStudentFeesConcessionByDivisionContext = () => {
  return useContext(StudentFeesConcessionByDivisionContext);
};
export { useStudentFeesConcessionByDivisionContext };

// Initial State
const initialState = {
  data: [],
  count: 0,
};

// provider of the context
const StudentFeesConcessionByDivisionProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { GetListAPI } = ApiHelper();

  // Reducer to set state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Name of the api to hit
  const apiName = API_NAMES.ADMIN.DASHBOARD.STUDENT_FEES_CONCESSION_DIVISION;

  // Fetches whole records from DB
  const getList = useCallback((params, callbackFunction) => {
    return GetListAPI(apiName, dispatch, params, callbackFunction);
  }, []);

  return (
    <StudentFeesConcessionByDivisionContext.Provider
      value={{
        data: state.data,
        count: state.count,
        getList,
      }}
    >
      {children}
    </StudentFeesConcessionByDivisionContext.Provider>
  );
};

export default StudentFeesConcessionByDivisionProvider;
