import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const MyProfile = React.lazy(() => import('modules/MyProfile'));

const routes = {
  element: <MyProfile />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/my-profile'],
};

export default routes;
