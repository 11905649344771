import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';
const StudentDashboard = React.lazy(() => import('modules/student/dashboard'));

const routes = {
  icon: <DashboardRoundedIcon />,
  title: 'Home',
  permittedRole: [USER_ROLE.ENTRANCE_STUDENT, USER_ROLE.STUDENT],
  path: ['/dashboard'],
  element: <StudentDashboard />,
  type: 'item',
};

export default routes;
