import PropTypes from 'prop-types';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAppSettingContext } from './appSetting';

import AppAuthProvider from 'core/AppAuthProvider';
import AccountContextProvider from './account';
import AdminContextProvider from './admin';
import AssignmentProvider from './assignment';
import AttendanceProvider from './attendance';
import NotificationContextProvider from './common/notificationContext';
import CompetitiveExamContextProvider from './competitiveExam';
import DefaultFilterProvider from './defaultFilterContext';
import ExamProvider from './exam';
import InventoryProvider from './inventory';
import LiveClassProvider from './liveClass';
import MasterContextProvider from './master';
import PayRollProvider from './payRoll';
import StudentContextProvider from './student';
import UserContextProvider from './user';
import WhatsappContextProvider from './whatsapp';

const AppContextProvider = ({ children }) => {
  const { appSetting } = useAppSettingContext();
  return (
    <ThemeProvider theme={createTheme(appSetting.theme)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <NotificationContextProvider>
          <AppAuthProvider>
            <MasterContextProvider>
              <DefaultFilterProvider>
                <AdminContextProvider>
                  <ExamProvider>
                    <StudentContextProvider>
                      <LiveClassProvider>
                        <InventoryProvider>
                          <AttendanceProvider>
                            <PayRollProvider>
                              <CompetitiveExamContextProvider>
                                <UserContextProvider>
                                  <AssignmentProvider>
                                    <WhatsappContextProvider>
                                      <AccountContextProvider>
                                        {children}
                                      </AccountContextProvider>
                                    </WhatsappContextProvider>
                                  </AssignmentProvider>
                                </UserContextProvider>
                              </CompetitiveExamContextProvider>
                            </PayRollProvider>
                          </AttendanceProvider>
                        </InventoryProvider>
                      </LiveClassProvider>
                    </StudentContextProvider>
                  </ExamProvider>
                </AdminContextProvider>
              </DefaultFilterProvider>
            </MasterContextProvider>
          </AppAuthProvider>
        </NotificationContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default AppContextProvider;

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
