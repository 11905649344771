import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const ExamScheduleSummary = React.lazy(
  () => import('modules/exam/examScheduleSummary')
);

const routes = {
  element: <ExamScheduleSummary />,
  title: 'Exam Summary',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/examschedulesummary'],
};

export default routes;
