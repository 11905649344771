import Error403 from 'modules/errorPages/Error403';
import { Navigate } from 'react-router-dom';
import { authRouteConfig } from './AuthRoutes';
import { errorPagesConfigs } from './ErrorPagesRoutes';
import accountConfig from './account';
import adminConfig from './admin';
import assignmentConfig from './assignment';
import attendanceConfig from './attendance';
import competitiveExamConfig from './competitiveExam';
import examConfig from './exam';
import inventoryConfig from './inventory';
import liveClassConfig from './liveClass';
import masterConfig from './master';
import myProfileConfig from './myProfile';
import payrollConfig from './payRoll';
import publicConfig from './public';
import studentConfig from './student';
import admissionAdminConfig from './student/admission/admissionAdmin';
import entranceAdminConfig from './student/enterance/entranceAdmin';
import testing from './testing';
import userConfig from './user';

const routesObj = [
  ...adminConfig,
  entranceAdminConfig,
  admissionAdminConfig,
  attendanceConfig,
  inventoryConfig,
  accountConfig,
  ...studentConfig,
  ...myProfileConfig,
  competitiveExamConfig,
  liveClassConfig,
  userConfig,
  ...masterConfig,
  examConfig,
  assignmentConfig,
  payrollConfig,
];

export const authorizedStructure = (loginUrl, userRole) => {
  return {
    fallbackPath: loginUrl,
    unAuthorizedComponent: <Error403 />,
    routes: routesObj,
    userRole: userRole,
  };
};

export const publicStructure = (initialUrl) => {
  return {
    fallbackPath: initialUrl,
    routes: [...authRouteConfig, ...publicConfig, ...testing],
  };
};

export const anonymousStructure = (initialUrl) => {
  return {
    routes: errorPagesConfigs.concat([
      {
        path: '/',
        element: <Navigate to={initialUrl} />,
      },
      {
        path: '*',
        element: <Navigate to="/error-pages/error-404" />,
      },
    ]),
  };
};
