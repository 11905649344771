// import { NavStyle } from 'constants/AppEnum';

import AppSettings from 'constants/AppEnum';
import AdminLayout from './AdminLayout';
import PublicLayout from './PublicLayout';
import StudentLayout from './StudentLayout';

const Layouts = {
  [AppSettings.ADMIN.navStyle]: AdminLayout,
  [AppSettings.STUDENT.navStyle]: StudentLayout,
  [AppSettings.PUBLIC.navStyle]: PublicLayout,
};

export default Layouts;
