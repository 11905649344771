import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';
const StudentFeesReceipt = React.lazy(
  () => import('modules/account/studentFeesReceipt')
);

const routes = {
  title: 'Fees Receipt',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/account/feesreceipts'],
  element: <StudentFeesReceipt />,
  type: 'item',
};

export default routes;
