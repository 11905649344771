import React from 'react';

const StudentDetails = React.lazy(
  () => import('modules/student/studentDetails')
);
const routes = {
  path: [
    '/studentdetails/personal',
    '/studentdetails/admission',
    '/studentdetails/fees',
    '/studentdetails/payment',
    '/studentdetails/inventory',
    '/studentdetails/attendace',
    '/studentdetails/competitiveexam',
    '/studentdetails/progresscard',
  ],
  element: <StudentDetails />,
};

export default routes;
