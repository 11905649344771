import { USER_ROLE } from 'constants/AppConstant';
import { MdInventory } from 'react-icons/md';
import inventorySetupRoutes from './inventorySetupRoutes';
import studentInventoryTransactionRoutes from './studentInventoryTransactionRoutes';

const menu = {
  id: 'Inventory',
  title: 'Inventory',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: 'collapse',
  icon: <MdInventory />,
  path: ['/inventoryMenu'],
  children: [inventorySetupRoutes, studentInventoryTransactionRoutes],
};

export default menu;
