import { NOTIFICATION_MESSAGES } from 'constants/ActionTypes';
import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useReducer } from 'react';
import { contextReducer } from 'reducers/common/notificationReducer';

const NotificationContext = createContext();
const NotificationActionsContext = createContext();

export const useNotificationContext = () => useContext(NotificationContext);
export const useNotificationActionsContext = () =>
  useContext(NotificationActionsContext);

const ContextState = {
  loading: true,
  error: '',
  message: '',
  currentTime: Date.now(),
};
const NotificationContextProvider = (props) => {
  const [state, dispatch] = useReducer(contextReducer, ContextState);

  const fetchStart = useCallback(() => {
    dispatch({ type: NOTIFICATION_MESSAGES.FETCH_START });
  }, []);

  const fetchSuccess = useCallback(() => {
    dispatch({ type: NOTIFICATION_MESSAGES.FETCH_SUCCESS });
  }, []);

  const fetchError = (error) => {
    if (error === 'Authentication token has been expired') {
      fetchSuccess();
      return;
    }
    dispatch({ type: NOTIFICATION_MESSAGES.FETCH_ERROR, payload: error });
  };

  const showMessage = (message) => {
    dispatch({ type: NOTIFICATION_MESSAGES.SHOW_MESSAGE, payload: message });
  };

  const clearInfoView = () => {
    dispatch({ type: NOTIFICATION_MESSAGES.HIDE_MESSAGE });
  };

  return (
    <NotificationContext.Provider value={state}>
      <NotificationActionsContext.Provider
        value={{
          fetchStart,
          fetchSuccess,
          fetchError,
          showMessage,
          clearInfoView,
        }}
      >
        {props.children}
      </NotificationActionsContext.Provider>
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;

NotificationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
