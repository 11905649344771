import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';
import { FaPhoneSquareAlt } from 'react-icons/fa';
const ContactUs = React.lazy(() => import('modules/public/contactus'));

const routes = {
  element: <ContactUs />,
  title: 'Contact Us',
  type: 'item',
  permittedRole: [USER_ROLE.PUBLIC],
  path: ['/contactus'],
  icon: <FaPhoneSquareAlt />,
};

export default routes;
