import PropTypes from 'prop-types';
import InventoryItemBranchStockTransactionProvider from './inventoryItemBranchStockTransactionContext';
import InventoryItemCategoryProvider from './inventoryItemCategoryContext';
import InventoryItemProvider from './inventoryItemContext';
import InventoryItemPurchaseProvider from './inventoryItemPurchaseContext';
import InventoryItemStandardBranchMappingContext from './inventoryItemStandardBranchMappingContext';
import InventoryItemStockProvider from './inventoryItemStockContext';
import InventoryItemTransactionProvider from './inventoryItemTransactionContext';

const InventoryProvider = ({ children }) => {
  return (
    <InventoryItemBranchStockTransactionProvider>
      <InventoryItemCategoryProvider>
        <InventoryItemProvider>
          <InventoryItemPurchaseProvider>
            <InventoryItemTransactionProvider>
              <InventoryItemStockProvider>
                <InventoryItemStandardBranchMappingContext>
                  {children}
                </InventoryItemStandardBranchMappingContext>
              </InventoryItemStockProvider>
            </InventoryItemTransactionProvider>
          </InventoryItemPurchaseProvider>
        </InventoryItemProvider>
      </InventoryItemCategoryProvider>
    </InventoryItemBranchStockTransactionProvider>
  );
};

export default InventoryProvider;

InventoryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
