import React from 'react';
import { USER_ROLE } from 'constants/AppConstant';

const AdmissionStudentProfile = React.lazy(
  () => import('modules/student/admission/admissionStudentProfile')
);
const routes = {
  path: ['/student/profile'],
  element: <AdmissionStudentProfile />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
};

export default routes;
