import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';
import { FaHome } from 'react-icons/fa';

const Home = React.lazy(() => import('modules/public/home'));

const routes = {
  element: <Home />,
  title: 'Home',
  type: 'item',
  permittedRole: [USER_ROLE.PUBLIC],
  path: ['/home'],
  icon: <FaHome />,
};

export default routes;
