import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const Exam = React.lazy(
  () => import('modules/exam/examSetup/examSchedule/Form/index')
);

const routes = {
  element: <Exam />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/exam/schedules/create', '/exam/schedules/edit'],
};

export default routes;
