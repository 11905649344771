import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const DemographicSetup = React.lazy(
  () => import('modules/master/system/demographicSetup')
);

const routes = {
  element: <DemographicSetup />,
  title: 'Demographic Setup',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    '/demographic/bloodgroups',
    '/demographic/castecategories',
    '/demographic/mothertongues',
    '/demographic/religions',
    '/demographic/occupations',
    '/demographic/designations',
  ],
};

export default routes;
