import React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import FooterWrapper from './FooterWrapper';
import { FaFacebookF, FaWhatsapp, FaYoutube } from 'react-icons/fa';

const AppFooter = ({ footer }) => {
  return (
    <>
      {footer && (
        <FooterWrapper className="footer ">
          <Box className="footerContainer">
            <Typography textAlign={'center'}>
              NETIZENS FOUNDATION ACADEMY © All Rights Reserved.
            </Typography>
            <Box
              sx={{
                my: 3,
                svg: {
                  fontSize: 40,
                  mx: 2,
                  p: 2,
                  borderRadius: '50%',
                  color: 'primary.contrastText',
                },
                '.whatsapp': { backgroundColor: '#44c052' },
                '.youtube': { backgroundColor: '#f60002' },
                '.facebook': { backgroundColor: '#0862f7' },
              }}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/8308380033?text=Hello%20I%20Want%20to%20get%20details!"
              >
                <FaWhatsapp className="whatsapp" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCIzIl9JbHf5WeL76-weRUhg"
              >
                <FaYoutube className="youtube" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/OfficialNetizensFoundationAcademy"
              >
                <FaFacebookF className="facebook" />
              </a>
            </Box>
          </Box>
        </FooterWrapper>
      )}
    </>
  );
};

export default AppFooter;
