import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const SchoolInfoSetup = React.lazy(
  () => import('modules/master/academic/schoolInfoSetup')
);

const routes = {
  element: <SchoolInfoSetup />,
  title: 'School Setup',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/school/schoolboards', '/school/schoolmediums'],
};
export default routes;
