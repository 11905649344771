import { PAGE, PAGE_SIZE } from './AppConstant';

export const API_NAMES = Object.freeze({
  ACCOUNT: {
    FEES_CONCESSION_VERIFICATION: 'feesconcessionverifications',
  },
  WHATSAPP: {
    WHTATSAPP_SESSION: 'whatsappsessions',
  },
  PAYROLL: {
    USER_SALARY_TRANSACTION: 'usersalarytransactions',
    USER_PAID_SALARY_TRANSACTION: 'userpaidsalarytransactions',
    SALARY_PAYMENT_METHOD: 'salarypaymentmethods',
    STATUTORY_COMPONENT: 'statutorycomponents',
    USER_SALARY: 'usersalaries',
    USER_SALARY_DEDUCTION: 'usersalarydeductions',
  },
  ASSIGNMENT: {
    ASSIGNMENT_TYPE: 'assignmenttypes',
    ASSIGNMENT_GROUP: 'assignmentgroups',
    ASSIGNMENT: 'assignments',
    ASSIGNMENT_QUESTION_GROUP: 'assignmentquestiongroups',
    ASSIGNMENT_HEADER_TYPE: 'assignmentheadertypes',
    PUBLISH_ASSIGNMENT: 'publishassignments',
    STUDENT_ASSIGNMENT: 'studentassignments',
    ASSIGNMENT_DATA_UPLOAD: 'assignmentdatauploads',
    ASSIGNMENT_SUMMARY: 'assignmentsummary',
    ASSIGNMENT_PAPER: 'assignmentpapers',
  },
  ATTENDANCE: {
    CAS: 'continuousabsentstudents',
    ATTENDANCE_DEVICE_LOG: 'studentattendancedevicelogs',
    STUDENT_ATTENDANCE_REPORT: 'studentattendancereports',
    STUDENT_MANUAL_ATTENDANCE: 'studentmanualattendance',
    USER_ATTENDANCE_LOG: 'userattendancelogs',
    USER_SHIFT_WISE_ATTENDANCE_LOG: 'usershiftwiseattendancelogs',
    USER_EXTRA_WORKING_SCHEDULE: 'userextraworkingschedules',
  },
  ADMIN: {
    DASHBOARD: {
      STUDENT_ATTENDANCE_SUMMARY: 'studentattendancesummary',
      ENTRANCE_STATISTICS: 'entrancestatistics',
      ENTRANCE_SUMMARY: 'entrancesummary',
      ADMISSION_SUMMARY: 'admissionsummary',
      PAYMENT_TRANSATION_SUMMARY: 'paymenttransactionssummary',
      PAYMENT_TRANSACTION_SUMMARY_BY_MONTH:
        'paymenttransactionssummarybymonths',
      PAYMENT_TRANSATION_SUMMARY_DIVISION:
        'paymenttransactionssummarydivisions',
      STUDENT_FEES_CONCESSION_DIVISION: 'studentfeesconcessiondivisions',
      STUDENT_FEES_CONCESSION: 'studentfeesconcessions',
      COMPETITIVE_EXAM_STATISTICS: 'competitiveexamsstatistics',
      USER_ATTENDANCE_SUMMARY: 'userattendancesummary',
    },
  },
  USER: {
    LOGIN: 'login',
    USER: 'users',
    BRANCH_MAPPING: 'userbranchmappings',
    EDUCATION: 'usereducations',
    BANK_ACCOUNT: 'userbankaccounts',
    PASSWORD: 'password',
    LEAVE_TYPE: 'leavetypes',
    LEAVE_REASON: 'leavereasons',
    USER_LEAVE_ALLOCATION: 'userleaveallocations',
    USER_REGULAR_WORKINGS_SCHEDULE: 'userregularworkingschedules',
    USER_LEAVE: 'userleaves',
    USER_LEAVE_RELIEVER: 'userleaverelievers',
  },
  STUDENT: {
    STUDENT_FEES_STRUCTURE_INSTALLMENT: 'studentfeesstructureinstallments',
    STUDENT_REGISTRATION: 'studentregistrations',
    STUDENT_LOGIN: 'studentlogin',
    STUDENT_FEES_RECEIPT: 'studentfeesreceipts',
    STUDENT_FEES_STRUCTURE_INSTALLMENT_BY_DUE_DATE:
      'studentfeesstructureinstallmentbyduedate',
    ADMISSION: {
      ADMISSION_STUDENT_ADMISSION_QUEUE: 'admissionstudentadmissionqueues',
      ADMISSION_STUDENT_PROFILE: 'admissionstudentprofiles',
      ADMISSION_STUDENT_ADMISSION: 'studentadmissions',
      ADMISSION_STUDENT_FEES_STRUCTURE: 'admissionstudentfeesstructures',
      VERIFY_SIBLING: 'verifysibling',
    },
    ENTRANCE: {
      ENTRANCE_STUDENT_ADMIT_CARD: 'entrancestudentadmitcards',
      ENTRANCE_STUDENT_RESULT: 'entrancestudentresults',
      ENTRANCE_STUDENT_APPLICATION: 'entrancestudentapplications',
      ENTRANCE_STUDENT_NOTIFICATION: 'entrancestudentnotifications',
    },
    PAYMENT_TRANSATION: 'paymenttransactions',
    PAYMENT_TRANSACTION_DATE: 'paymenttransactiondates',
  },
  MASTER: {
    SYSTEM: {
      MAS_HOLIDAY: 'masholidays',
      HOLIDAY: 'holidays',
      MONGODB_BACKUP: 'mongodbbackups',
      LAYOUT_TYPE: 'layouttypes',
      USER_ROLE: 'userroles',
      DISTRICT: 'districts',
      STATE: 'states',
      COUNTRY: 'country',
      BLOOD_GROUP: 'bloodgroups',
      CASTE_CATEGORY: 'castecategories',
      MOTHER_TONGUE: 'mothertongues',
      RELIGION: 'religions',
      OCCUPATION: 'occupations',
      PERMISSION: 'permissions',
      MODULE: 'modules',
      ROUTE: 'routes',
      GENDER: 'genders',
      DESIGNATION: 'designations',
      LOCKER: 'lockers',
    },
    ACADEMIC: {
      WORKING_LOAD_SUMMARY: 'workingloadsummary',
      TIME_TABLE: 'timetables',
      TIME_SLOT: 'timeslots',
      DIVISION_SUBJECT_MAPPING: 'divisionsubjectmappings',
      DIVISION_GROUP: 'divisiongroups',
      BUILDING: 'buildings',
      PAYMENT_METHOD: 'paymentmethods',
      ADMISSION_SOURCE: 'admissionsources',
      BRANCH: 'branches',
      BRANCH_CITY: 'branchcities',
      ACADEMIC_YEAR: 'academicyears',
      STANDARD: 'standards',
      SUBJECT: 'subjects',
      SUBJECT_TOPIC: 'subjecttopics',
      DIVISION: 'divisions',
      VIRTUAL_DIVISION: 'virtualdivisions',
      COMPETITIVE_EXAM: 'competitiveexams',
      SCHOOL_BOARD: 'schoolboards',
      SCHOOL_MEDIUM: 'schoolmediums',
      ADMISSION_MODE: 'admissionmodes',
      LEARNING_MODE: 'learningmodes',
      CLASSROOM: 'classrooms',
      IDENTITY_CARD: 'identitycards',
      ACADEMIC_ACHIEVEMENT: 'academicachievements',
      ENTRANCE_TEST_SCHEDULE: 'entrancetestschedules',
      ENTRANCE_SCHOLARSHIP: 'entrancescholarships',

      STANDARD_BRANCH_MAPPING: 'standardbranchmappings',
      STANDARD_ENTRANCE_TEST_SCHEDULE_MAPPING:
        'standardentrancetestschedulemappings',
      STANDARD_BRANCH_ACADEMIC_YEAR_MAPPING:
        'standardbranchacademicyearmappings',
      DIVISION_STANDARD_BRANCH_ACADEMIC_YEAR_MAPPING:
        'divisionstandardbranchacademicyearmappings',

      FEES_RECEIPT_BOOK_TYPE: 'feesreceiptbooktypes',
      FEES_TYPE: 'feestypes',
      FEES_STRUCTURE: 'feesstructures',
      FEES_INSTALLMENT: 'feesinstallments',
      FEES_INSTALLMENT_TYPE: 'feesinstallmenttypes',
      RECEIPT: 'receipts',

      FEES_CONCESSION: 'feesconcessions',
      FEES_CONCESSION_TYPE: 'feesconcessiontypes',
      FEES_CONCESSION_GROUP: 'feesconcessiongroups',
    },
    QUESTION_BANK: {
      COMPETITIVE_TAG: 'competitivetags',
      SOURCE_TAG: 'sourcetags',
      DIFFICULTY_LEVEL: 'difficultylevels',
      QUESTION_TYPE: 'questiontypes',
      SUBJECT: 'subjects',
      SUBJECT_TOPIC: 'subjecttopics',
      DIVISION_SUBJECT_TOPIC: 'divisionsubjecttopics',
      DIVISION_GROUP_WISE_TOPIC_ALLOCATION: 'divisiongroupwisetopicallocations',
      QUESTION: 'questions',
      QUESTION_GROUP: 'questiongroups',
      SUBJECT_GROUP: 'subjectgroups',
    },
    COMMUNICATION: {
      EMAIL_GATEWAY: 'emailgateways',
      SMS_TEMPLATE: 'smstemplates',
      SMS_SENDER: 'smssenders',
      SMS_GATEWAY: 'smsgateways',
      WHATSAPP_GATEWAY: 'whatsappgateways',
      WHATSAPP_TEMPLATE: 'whatsapptemplates',
      ATTENDANCE_DEVICE_CONFIGURATION: 'attendancedeviceconfigurations',
      ATTENDANCE_DEVICE_USER_UPLOAD: 'attendancedeviceuseruploads',
      ATTENDANCE_DEVICE: 'attendancedevices',
      ZOOM_ACCOUNT: 'zoomaccounts',
    },
  },
  INVENTORY: {
    INVENTORY_ITEM_CATEGORY: 'inventoryitemcategories',
    INVENTORY_ITEM: 'inventoryitems',
    INVENTORY_ITEM_PURCHASE: 'inventoryitempurchases',
    INVENTORY_ITEM_STOCK: 'inventoryitemstocks',
    INVENTORY_ITEM_TRANSACTION: 'inventoryitemtransactions',
    INVENTORY_ITEM_STANDARD_BRANCH_MAPPING:
      'inventoryitemstandardbranchmappings',
    INVENTORY_ITEM_BRANCH_STOCK_TRANSACTION:
      'inventoryitembranchstocktransactions',
  },
  LIVE_CLASS: {
    LIVE_CLASS_SCHEDULE: 'liveclassschedules',
    LIVE_CLASS_TYPE: 'liveclasstypes',
    LIVE_CLASS_SIGNATURE: 'liveclasssignatures',
  },
  COMPETITIVE_EXAM: {
    COMPETITIVE_EXAM_STUDENT_APPLICATION: 'competitiveexamstudentapplications',
    COMPETITIVE_EXAM: 'competitiveexams',
    COMPETITIVE_EXAM_SCHEDULE: 'competitivexamschedules',
  },
  EXAM: {
    PUBLISH_EXAM: 'publishexams',
    EXAM_PATTERN_TEMPLATE: 'exampatterntemplates',
    EXAM_PAPER: 'exampapers',
    EXAM_GROUP: 'examgroups',
    EXAM_TYPE: 'examtypes',
    EXAM_SCHEDULE: 'examschedules',
    EXAM_QUESTION: 'examquestions',
    EXAM_TERM: 'examterms',
    GRADE: 'grades',
    EXAM_DATA_UPLOAD: 'examdatauploads',
    EXAM_STUDENT: 'examstudents',
    EXAM_REPORT_CARD: 'examreportcards',
    EXAM_SCHEDULE_SUMMARY: 'examschedulesummary',
  },
});

export const DEFAULT_API_PARAMS = Object.freeze({
  page: PAGE,
  pageSize: PAGE_SIZE,
  search: '',
});
