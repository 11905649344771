// React imports
import { createContext, useContext, useReducer } from 'react';

// Generic API helper
import ApiHelper from 'helpers/ApiHelper';

// Constant API names and default api params
import { API_NAMES } from 'constants/ApiConstants';

// Reducer for changing state
import reducer from 'reducers/user/userBankAccountReducer';
import { useUserRegistrationContext } from './userRegistrationContext';

// context to be used in the app
const UserBankAccountContext = createContext();

// hook to use the context states in the ui
const useUserBankAccountContext = () => {
  return useContext(UserBankAccountContext);
};
export { useUserBankAccountContext };

// Initial UserBankAccount
const initialUserBankAccount = {
  data: [],
  count: 0,
};

// provider of the context
const UserBankAccountProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { GetListAPI, GetAPI, UpdateAPI, CreateAPI, DeleteAPI } = ApiHelper();
  const { setItem } = useUserRegistrationContext();

  // Reducer to set state
  const [state, dispatch] = useReducer(reducer, initialUserBankAccount);

  // Name of the api to hit
  const apiName = API_NAMES.USER.BANK_ACCOUNT;

  // Fetches whole records from DB
  const getList = (params, callbackFunction) => {
    return GetListAPI(
      API_NAMES.USER.USER_LIST,
      dispatch,
      params,
      callbackFunction
    );
  };

  // Fetches single record in records of DB
  const getItem = (_id) => {
    GetAPI(API_NAMES.USER.USER_LIST, dispatch, _id);
  };

  // Update single record in records of DB
  const updateItem = (formData, setSubmitting, resetForm, handleAddClose) => {
    UpdateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose
    );
  };

  // Add new record in records of DB
  const createItem = (formData, setSubmitting, resetForm, handleAddClose) => {
    CreateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose,
      '',
      (data) => {
        setItem(data);
      }
    );
  };

  // Delete on or may records from records in DB
  const deleteItems = (params, _ids) => {
    DeleteAPI(apiName, dispatch, params, _ids);
  };

  return (
    <UserBankAccountContext.Provider
      value={{
        data: state.data,
        count: state.count,
        getList,
        getItem,
        updateItem,
        createItem,
        deleteItems,
      }}
    >
      {children}
    </UserBankAccountContext.Provider>
  );
};

export default UserBankAccountProvider;
