import { USER_ROLE } from 'constants/AppConstant';
import { GiProgression } from 'react-icons/gi';
import competitiveExamSetupRoutes from './competitiveExamSetupRoutes';
import competitiveExamStatisticsRoutes from './competitiveExamStatisticsRoutes';
import competitiveExamStudentApplicationRoutes from './competitiveExamStudentApplicationRoutes';

const menu = {
  id: 'Competitive Exam',
  title: 'Competitive Exam',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: 'collapse',
  icon: <GiProgression />,
  path: ['/competitiveExamMenu'],
  children: [
    competitiveExamStatisticsRoutes,
    competitiveExamSetupRoutes,
    competitiveExamStudentApplicationRoutes,
  ],
};

export default menu;
