import PropTypes from 'prop-types';
import ExamDataUploadProvider from './examDataUploadContext';
import ExamGroupProvider from './examGroupContext';
import ExamPaperProvider from './examPaperContext';
import ExamPatternTemplateProvider from './examPatternTemplateContext';
import ExamQuestionProvider from './examQuestionContext';
import ExamReportCardProvider from './examReportCardContext';
import ExamScheduleProvider from './examScheduleContext';
import ExamScheduleSummaryProvider from './examScheduleSummaryContext';
import ExamStudentProvider from './examStudentContext';
import ExamTermProvider from './examTermContext';
import ExamTypeProvider from './examTypeContext';
import GradeProvider from './gradeContext';
import PublishExamProvider from './publishExamContext';
const ExamProvider = ({ children }) => {
  return (
    <ExamPaperProvider>
      <ExamTermProvider>
        <ExamScheduleSummaryProvider>
          <ExamReportCardProvider>
            <GradeProvider>
              <PublishExamProvider>
                <ExamDataUploadProvider>
                  <ExamStudentProvider>
                    <ExamScheduleProvider>
                      <ExamQuestionProvider>
                        <ExamPatternTemplateProvider>
                          <ExamGroupProvider>
                            <ExamTypeProvider>{children} </ExamTypeProvider>
                          </ExamGroupProvider>
                        </ExamPatternTemplateProvider>
                      </ExamQuestionProvider>
                    </ExamScheduleProvider>
                  </ExamStudentProvider>
                </ExamDataUploadProvider>
              </PublishExamProvider>
            </GradeProvider>
          </ExamReportCardProvider>
        </ExamScheduleSummaryProvider>
      </ExamTermProvider>
    </ExamPaperProvider>
  );
};

export default ExamProvider;

ExamProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
