import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const AdmissionStudentProfileEdit = React.lazy(
  () => import('modules/student/admission/admissionStudentProfileEdit')
);
const routes = {
  path: ['/student/editprofile'],
  element: <AdmissionStudentProfileEdit />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
};

export default routes;
