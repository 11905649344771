import React, { useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import VerticalCollapse from '../VerticalCollapse';
import VerticalItem from '../VerticalItem';
import VerticalNavGroupItem from './VerticalNavGroupItem';
import { useAppSettingContext } from 'contexts/appSetting';

const VerticalNavGroup = ({ item, level }) => {
  const { appSetting } = useAppSettingContext();
  const { sidebarTextColor } = appSetting.sidebar;

  if (!item?.hasPermission) {
    return null;
  }

  return (
    <>
      <VerticalNavGroupItem
        level={level}
        sidebarTextColor={sidebarTextColor}
        component="div"
        className={clsx('nav-item nav-item-header')}
      >
        {item.title}
      </VerticalNavGroupItem>

      {item.children && (
        <>
          {item.children.map((item, index) => (
            <React.Fragment key={index}>
              {item.type === 'group' && (
                <NavVerticalGroup item={item} level={level} />
              )}

              {item.type === 'collapse' && (
                <VerticalCollapse item={item} level={level} />
              )}

              {item.type === 'item' && (
                <VerticalItem item={item} level={level} />
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
};

const NavVerticalGroup = VerticalNavGroup;

export default NavVerticalGroup;
