// React imports
import { createContext, useContext } from 'react';

// Generic API helper
import ApiHelper from 'helpers/ApiHelper';

// Constant API names and default api params
import { API_NAMES } from 'constants/ApiConstants';

// context to be used in the app
const PasswordContext = createContext();

// hook to use the context states in the ui
const usePasswordContext = () => {
  return useContext(PasswordContext);
};
export { usePasswordContext };

// provider of the context
const PasswordProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { CreateAPI } = ApiHelper();

  // Add new record in records of DB
  const createItem = (formData, setSubmitting, resetForm, handleAddClose) => {
    const apiName = API_NAMES.USER.PASSWORD;

    CreateAPI(
      apiName,
      null,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose,
      'Password updated successfully.'
    );
  };

  return (
    <PasswordContext.Provider
      value={{
        createItem,
      }}
    >
      {children}
    </PasswordContext.Provider>
  );
};

export default PasswordProvider;
