export const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export const USER_ROLE = Object.freeze({
  PUBLIC: 'PUBLIC',
  STUDENT: 'STUDENT',
  ENTRANCE_STUDENT: 'ENTRANCE_STUDENT',
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  DESK_USER: 'DESK_USER',
  TEACHER: 'TEACHER',
  BRANCH_HEAD: 'BRANCH_HEAD',
  DIRECTOR: 'DIRECTOR',
  ACCOUNTANT: 'ACCOUNTANT',
});

export const MALE_NAME_INITIALS = [
  { _id: 'MR', male_initial_name: 'Mr.' },
  { _id: 'DR', male_initial_name: 'Dr.' },
];

export const FEMALE_NAME_INITIALS = [
  { _id: 'MRS', female_initial_name: 'Mrs.' },
  { _id: 'DR', female_initial_name: 'Dr.' },
];

export const LIVE_CLASS_CONSTANT = Object.freeze({
  LIVE_CLASS_TYPE: {
    REGULAR_CLASS: 'Regular Class',
    OTHER_CLASS: 'Other Class',
  },
  MEETING_RECURRENCE: {
    DAILY: 1,
    WEEKLY: 2,
    MONTHLY: 3,
  },
  MEETING_TYPE: {
    'Instant Meeting': 1,
    'Scheduled Meeting': 2,
    'Recurring Meeting': 8,
    'Screen Share Only Meeting': 10,
  },
});

export const ACADEMIC_CONSTANT = Object.freeze({
  PAYMENT_METHOD: {
    RAZORPAY: 'RAZORPAY',
  },
  ADMISSION_TYPE: {
    REGULAR: 'REGULAR',
    'TEST SERIES': 'TEST SERIES',
  },
  TOPIC_DELIVERY_MODE: {
    VIDEO: 'Video',
    CLASSROOM: 'Classroom',
  },
  IS_VERIFIED: {
    Yes: true,
    No: false,
  },
  GENDER: {
    MALE: 'Male',
    FEMALE: 'Female',
  },
  TEACHING_STATUS: {
    PENDING: 'PENDING',
    'IN PROGRESS': 'IN PROGRESS',
    COMPLETED: 'COMPLETED',
  },
  FEES_STRUCTURE_TYPE: {
    NSAT: 'NSAT',
    ADMISSION: 'ADMISSION',
    COMPETITIVE_EXAM: 'COMPETITIVE_EXAM',
    EDUCATIONAL_KIT: 'EDUCATIONAL_KIT',
    OTHER: 'OTHER',
  },
  FEES_CONCESSION_TYPE: {
    SIBLING: 'SIBLING',
    DIRECTOR: 'DIRECTOR',
  },
  FEES_CONCESSION_MODE_OBJ: {
    AMOUNT: 'AMOUNT',
    PERCENTAGE: 'PERCENTAGE',
  },
  BRANCH_TYPE_OBJ: {
    SCHOOL: 'SCHOOL',
    FOUNDATION: 'FOUNDATION',
  },
  FEES_CONCESSION_MODE: [
    {
      _id: 'AMOUNT',
      value: 'AMOUNT',
    },
    {
      _id: 'PERCENTAGE',
      value: 'PERCENTAGE',
    },
  ],
  ADMISSION_SELECTION_METHOD: [
    {
      _id: 'MERIT',
      value: 'MERIT',
    },
    {
      _id: 'SCORE',
      value: 'SCORE',
    },
  ],
  BRANCH_TYPE: [
    {
      _id: 'FOUNDATION',
      value: 'FOUNDATION',
    },
    {
      _id: 'SCHOOL',
      value: 'SCHOOL',
    },
  ],
});

export const QUESTION_BANK_CONSTANT = Object.freeze({
  OPTIONS_PER_ROW_VALUE: {
    1: '100%',
    2: '50%',
    4: '25%',
  },
  OPTIONS_PER_ROW: [
    {
      _id: '1',
      options_per_row_name: '1 Per Row',
    },
    {
      _id: '2',
      options_per_row_name: '2 Per Row',
    },
    {
      _id: '4',
      options_per_row_name: '4 Per Row',
    },
  ],
  QUESTION_TYPE: {
    MULTIPLE_CHOICE: 'Multiple Choice',
    SINGLE_CHOICE: 'Single Choice',
    TRUE_FALSE: 'True False',
    SUBJECTIVE: 'Subjective',
    MATCH_THE_FOLLOWING: 'Match The Following',
  },
  VERIFICATION_STATUS: {
    VERIFIED: 'Verified',
    PENDING: 'Pending',
    UNDER_CORRECTION: 'Under Correction',
  },
});

export const ASSIGNMENT_CONSTANT = Object.freeze({});
export const EXAM_CONSTANT = Object.freeze({
  OPTION_TYPE: {
    CAPITAL_ALPHABET: 'Capital Alphabet',
    SMALL_ALPHABET: 'Small Alphabet',
    NUMBER: 'Number',
    SMALL_ROMAN: 'Small Roman',
    CAPITAL_ROMAN: 'Capital Roman',
  },
  REPORT_TYPE: {
    SUBJECT_WISE: 'SUBJECT_WISE',
    COMBINED: 'COMBINED',
  },
  EXAM_MODE: {
    ONLINE: 'ONLINE',
    OFFLINE: 'OFFLINE',
  },
  ATTENDANCE_STATUS: {
    PRESENT: 'PRESENT',
    ABSENT: 'ABSENT',
  },
  RESULT_STATUS: {
    PASS: 'PASS',
    FAIL: 'FAIL',
  },
  RESULT_UPDATED_STATUS: {
    UPDATED: 'UPDATED',
    PENDING: 'PENDING',
  },
  ATTENDANCE_UPDATED_STATUS: {
    UPDATED: 'UPDATED',
    PENDING: 'PENDING',
  },
});
export const USER_CONSTANT = {
  WORK_SCHEDULE_TYPE: {
    FULL_DAY: 'FULL DAY',
    HALF_DAY: 'HALF DAY',
    QUARTER_DAY: 'QUARTER DAY',
  },
  ATTENDANCE_STATUS: {
    PRESENT: 'PRESENT',
    ABSENT: 'ABSENT',
    PENDING: 'PENDING',
    EARLY: 'EARLY',
    HALF_DAY: 'HALF DAY',
    EARLY_GOING: 'EARLY GOING',
    LATE_COMING: 'LATE COMING',
    LATE: 'LATE',

    'MISSED PUNCH': 'MISSED PUNCH',

    FULL_DAY_LEAVE: 'FDL',

    HALF_DAY_LEAVE_PRESENT: 'HDL PRESENT',
    HALF_DAY_LEAVE_ABSENT: 'HDL ABSENT',

    QUARTER_DAY_LEAVE_PRESENT: 'QDL PRESENT',
    QUARTER_DAY_LEAVE_ABSENT: 'QDL ABSENT',

    FULL_DAY_WEEK_OFF: 'FDWO',

    HALF_DAY_WEEK_OFF_PRESENT: 'HDWO PRESENT',
    HALF_DAY_WEEK_OFF_ABSENT: 'HDWO ABSENT',

    QUARTER_DAY_WEEK_OFF_PRESENT: 'QDWO PRESENT',
    QUARTER_DAY_WEEK_OFF_ABSENT: 'QDWO ABSENT',
  },
  LEAVE_STATUS: {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
  },
  LEAVE_PAYMENT_TYPE: {
    PAID: 'PAID',
    UNPAID: 'UNPAID',
    WEEK_OFF: 'WEEK OFF',
  },
  LEAVE_TYPE: {
    HALF_DAY: 'HALF DAY',
    FULL_DAY: 'FULL DAY',
    QUARTER_DAY: 'QUARTER DAY',
  },
  CURRENT_STAGE: {
    PERSONAL: 0,
    'BRANCH ALLOCATION': 1,
    'BANK ACCOUNT': 2,
    'EDUCATION DETAILS': 3,
    COMPLETED: 4,
  },
  MARITAL_STATUS: {
    SINGLE: 'SINGLE',
    MARRIED: 'MARRIED',
  },
};

export const STUDENT_CONSTANT = Object.freeze({
  MANUAL_CONCESSIONS: ['ONLINE MODE', 'DIRECTOR'],
  ADMISSION_STATUS: {
    PENDING: 'PENDING',
    ADMITTED: 'ADMITTED',
  },
  CURRENT_STAGE: {
    PERSONAL: 0,
    ADMISSION: 1,
    FEES: 2,
    COMPLETED: 3,
  },
  ADMISSION_MODE: {
    ENTRANCE: 'ENTRANCE',
    MIGRATED: 'MIGRATED',
    RENEWAL: 'RENEWAL',
    ONLINE: 'ONLINE',
  },
  PAYMENT_STATUS: {
    PAID: 'PAID',
    PARTIAL_PAID: 'PARTIAL_PAID',
    PENDING: 'PENDING',
    REMAINING: 'REMAINING',
  },
  RESULT_STATUS: {
    ELIGIBLE_FOR_ADMISSION: 'ELIGIBLE FOR ADMISSION',
    NOT_ELIGIBLE: 'NOT ELIGIBLE',
    PENDING: 'PENDING',
    ELIGIBLE_FOR_ORAL: 'ELIGIBLE FOR ORAL TEST',
    SELECTION_ON_MERIT: 'SELECTION ON MERIT',
  },
  ENTRANCE: {
    STUDENT_MAX_ATTEMPTS: 3,
    SMS_TYPE: {
      ABSENT: 'ABSENT',
      RESULT: 'RESULT',
    },
  },
  ATTENDANCE: {
    PRESENT: 'PRESENT',
    ABSENT: 'ABSENT',
    PENDING: 'PENDING',
    LATE: 'LATE',
    EARLY: 'EARLY',
    HALF_DAY: 'HALF DAY',
    LATE_COMING: 'LATE COMING',
    EARLY_GOING: 'EARLY GOING',
    FULL_DAY_LEAVE: 'FULL DAY LEAVE',
    HALF_DAY_LEAVE: 'HALF DAY LEAVE',
    QUARTER_DAY_LEAVE: 'QUARTER DAY LEAVE',
  },
  ADMISSION_SELECTION_METHOD: {
    MERIT: 'MERIT',
    SCORE: 'SCORE',
  },
  MEASURMENT_TYPE: {
    PICS: 'PICS',
    SETS: 'SETS',
    BOX: 'BOX',
  },
});

export const INVENTORY_CONSTANT = {
  TRANSACTION_TYPE: {
    RETURN: 'RETURN',
    ISSUE: 'ISSUE',
  },
  USER_TYPE: {
    STUDENT: 'STUDENT',
    SYSTEM_USER: 'SYSTEM_USER',
  },
};

export const COMMON = Object.freeze({
  STATUS: {
    DARK_GREEN: 'DARK_GREEN',
    DARK_RED: 'DARK_RED',
    DARK_BLUE: 'DARK_BLUE',
    DARK_YELLOW: 'DARK_YELLOW',
    GREEN: 'GREEN',
    RED: 'RED',
    BLUE: 'BLUE',
    YELLOW: 'YELLOW',
    PENDING: 'PENDING',
    VERIFIED: 'VERIFIED',
    REJECTED: 'REJECTED',
  },
});
export const initialUrl = process.env.REACT_APP_INITIAL_URL; // this url will open after login

export const PAGE = 0;
export const PAGE_SIZE = 15;
export const PAGINATION_OPTIONS = [15, 25, 50, 100];

export const RAZOR_PAY = Object.freeze({
  STATUS: {
    FAILD: 'FAILED',
    SUCCEEDED: 'SECCEEDED',
    TIMEOUT: 'TIMEOUT',
    CANCELLED: 'CANCELLED',
  },
});

export const PAYROLL_CONSTANTS = Object.freeze({
  CALCULATION_TYPE: {
    PERCENTAGE: 'PERCENTAGE',
    FIXED_AMOUNT: 'FIXED AMOUNT',
  },
  STATUTORY_TYPE: {
    DEDUCTION: 'DEDUCTION',
    EARNING: 'EARNING',
  },
});

export const CSS_CONSTANTS = Object.freeze({
  Z_INDEX: {
    APP_DIALOG: 15,
    HEADER: 5,
    SIDEBAR_WRAPPER: 9,
    HORIZONTAL_COLLAPSE: 10,
    HORIZONTAL_GROUP: 10,
    VERTICAL_COLLAPSE_ITEM: 10,
    SIDEBAR_LIST_ITEM: 10,
    ATTENDANCE: -100,
    // SIDEBAR: 1,
  },
  FORM_SIZE: Object.freeze({
    EXTRA_EXTRA_LARGE: 1300,
    EXTRA_LARGE: 1120,
    BIG: 900,
    MEDIUM: 650,
    SMALL: 500,
  }),
  FONT_SIZE: {
    PARA: 17,
    HEADING_1: 25,
    HEADING_2: 23,
    HEADING_3: 21,
    HEADING_4: 19,
  },
});

export const SYSTEM_CONSTANTS = Object.freeze({
  MONTHS: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  DAYS_ARRAY: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  DAYS: {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
  },
  LAYOUT_TYPE: {
    ADMIN: 'ADMIN',
    PUBLIC: 'PUBLIC',
    STUDENT: 'STUDENT',
  },
  PERMISSIONS_OBJ: Object.freeze({
    GET: 'GET',
    GET_LIST: 'GET_LIST',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE',
    EXPORT: 'EXPORT',
    PRINT: 'PRINT',
  }),
  PERMISSIONS: [
    {
      _id: 'GET',
      permission_name: 'GET',
    },
    {
      _id: 'GET_LIST',
      permission_name: 'GET_LIST',
    },
    {
      _id: 'PUT',
      permission_name: 'PUT',
    },
    {
      _id: 'POST',
      permission_name: 'POST',
    },
    {
      _id: 'DELETE',
      permission_name: 'DELETE',
    },
    {
      _id: 'EXPORT',
      permission_name: 'EXPORT',
    },
    {
      _id: 'PRINT',
      permission_name: 'PRINT',
    },
  ],
  PHOTO: {
    WIDTH: 230,
    HEIGHT: 260,
  },
  AADHAR: {
    WIDTH: 300,
    HEIGHT: 180,
  },
  PASSWORD: {
    UPDATE: 'UPDATE',
    FORGOT: 'FORGOT',
  },
});
