import admissionStudentConfig from './admission/admissionStudent';
import entranceStudentConfig from './enterance';
import studentDashboardRoutes from './studentDashboardRoutes';
import studentDetailsRoutes from './studentDetailsRoutes';
import studentProfileRoutes from './studentProfileRoutes';

export default [
  studentDashboardRoutes,
  studentProfileRoutes,
  ...admissionStudentConfig,
  ...entranceStudentConfig,
  studentDetailsRoutes,
];
