import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const UserExtraWorkingSchedule = React.lazy(
  () => import('modules/user/userExtraWorkingSchedule')
);

const routes = {
  title: 'Holiday Working Schedule',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/user/extraworkingschedules'],
  element: <UserExtraWorkingSchedule />,
  type: 'item',
};

export default routes;
