import { USER_ROLE } from 'constants/AppConstant';
import { PiStudentBold } from 'react-icons/pi';

import studentRegistrationListRoutes from '../../studentRegistrationListRoutes';
import admissionStudentAdmissionQueueRoutes from './admissionStudentAdmissionQueueRoutes';
import admissionStudentAdmissions from './admissionStudentAdmissionRoutes';
import admissionStudentFoundationRegistrationRoutes from './admissionStudentFoundationRegistrationRoutes';
import admissionStudentInventoryRoutes from './admissionStudentInventoryRoutes';
import admissionStudentProfileEditRoutes from './admissionStudentProfileEditRoutes';
import admissionStudentProfileRoutes from './admissionStudentProfileRoutes';
import admissionStudentSchoolRegistrationRoutes from './admissionStudentSchoolRegistrationRoutes';
import admissionStudentRollNumberRoutes from './admissionStudentRollNumberRoutes'
const routes = {
  title: 'Student ',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: 'collapse',
  icon: <PiStudentBold />,
  path: ['/studentMenu'],
  children: [
    studentRegistrationListRoutes,
    admissionStudentProfileRoutes,
    admissionStudentFoundationRegistrationRoutes,
    admissionStudentSchoolRegistrationRoutes,
    admissionStudentAdmissionQueueRoutes,
    admissionStudentAdmissions,
    admissionStudentInventoryRoutes,
    admissionStudentProfileEditRoutes,
    admissionStudentRollNumberRoutes,
  ],
};

export default routes;
