import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const CompetitiveExamSetup = React.lazy(
  () => import('modules/competitiveExam/competitiveExamSetup')
);

const routes = {
  element: <CompetitiveExamSetup />,
  title: 'Competitive Exam Setup',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    '/competitiveexams',
    '/competitiveexams/schedule',
    '/competitiveexams/applications',
  ],
};

export default routes;
