import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const BulkUpdateRegularWorkingSchedule = React.lazy(
  () => import('modules/user/bulkUpdateRegularWorkingSchedule')
);

const routes = {
  element: <BulkUpdateRegularWorkingSchedule />,
  title: 'Bulk Working Schedule',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/bulkupdateregularworkingschedule'],
};

export default routes;
