import { USER_ROLE } from 'constants/AppConstant';
import casRoutes from './casRoutes';
import studentAttendanceDeviceLogRoutes from './studentAttendanceDeviceLogRoutes';
import studentAttendanceReportRoutes from './studentAttendanceReportRoutes';
import studentManualAttendanceRoutes from './studentManualAttendanceRoutes';
import userShiftWiseAttendanceLogRoutes from './userShiftWiseAttendanceLogRoutes';

import { TbDeviceCctv } from 'react-icons/tb';

const menu = {
  id: 'Attendance',
  title: 'Attendance',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: 'collapse',
  icon: <TbDeviceCctv />,
  path: ['/attendanceMenu'],
  children: [
    studentAttendanceReportRoutes,
    studentAttendanceDeviceLogRoutes,
    studentManualAttendanceRoutes,
    casRoutes,
    userShiftWiseAttendanceLogRoutes,
  ],
};

export default menu;
