import React from 'react';
import { USER_ROLE } from 'constants/AppConstant';
import { FaAddressCard } from 'react-icons/fa';

const EntranceAdmitCard = React.lazy(
  () => import('modules/student/entrance/entranceStudentAdmitCard')
);

const routes = {
  icon: <FaAddressCard />,
  permittedRole: [USER_ROLE.ENTRANCE_STUDENT],
  title: 'Hall Ticket',
  path: ['/entrance/admitcard'],
  element: <EntranceAdmitCard />,
  type: 'item',
};

export default routes;
