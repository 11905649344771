import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const InventorySetup = React.lazy(
  () => import('modules/inventory/inventorySetup')
);

const routes = {
  element: <InventorySetup />,
  title: 'Inventory Setup',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: [
    '/inventory/itemcategories',
    '/inventory/items',
    '/inventory/itempurchases',
    '/inventory/itemstocks',
    '/inventory/itemstocktransactions',
    '/inventory/itemstandardbranchmappings',
  ],
};

export default routes;
