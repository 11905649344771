import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import AppContentView from '../../AppContentView';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import StudentLayoutWrapper from './StudentLayoutWrapper';
import MainContent from './MainContent';
import StudentLayoutContainer from './StudentLayoutContainer';
import { useLocation } from 'react-router-dom';
import PropsTypes from 'prop-types';
import { useAppSettingContext } from 'contexts/appSetting';

const StudentLayout = ({ routes, routesConfig }) => {
  const { pathname } = useLocation();
  const { appSetting } = useAppSettingContext();
  const [isNavCollapsed, setNavCollapsed] = useState(false);

  const toggleNavCollapsed = () => {
    setNavCollapsed(!isNavCollapsed);
  };
  useEffect(() => {
    if (isNavCollapsed) setNavCollapsed(!isNavCollapsed);
  }, [pathname]);

  return (
    <StudentLayoutContainer>
      <StudentLayoutWrapper>
        <AppSidebar
          routesConfig={routesConfig}
          isNavCollapsed={isNavCollapsed}
          toggleNavCollapsed={toggleNavCollapsed}
        />

        <MainContent>
          <AppHeader
            toggleNavCollapsed={toggleNavCollapsed}
            routesConfig={routesConfig}
          />
          <AppContentView routes={routes} />
          <AppFooter footer={appSetting?.footer} />
        </MainContent>
      </StudentLayoutWrapper>
    </StudentLayoutContainer>
  );
};

export default StudentLayout;
StudentLayout.propTypes = {
  routes: PropsTypes.object.isRequired,
  routesConfig: PropsTypes.array.isRequired,
};
