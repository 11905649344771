import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const Dashboard = React.lazy(() => import('modules/admin/dashboard'));

const routes = {
  element: <Dashboard />,
  title: 'Dashboard',
  type: 'item',
  icon: <DashboardRoundedIcon />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/admin/dashboard'],
};

export default routes;
