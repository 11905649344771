import { Hidden, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import AppSettings, { FONTS } from 'constants/AppEnum';
import React from 'react';

import { IoCall } from 'react-icons/io5';
import { Link } from 'react-router-dom';

const NotificationBar = () => {
  const [open, setOpen] = React.useState(true);

  return (
    <Collapse in={open}>
      <Box
        sx={{
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
          padding: '2px 0',
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <Box
          sx={{
            width: '100%',
            margin: '0 auto',
            maxWidth: { lg: 1020, xl: 1020 },
            p: 1.5,
            px: 5,
            color: (theme) => theme.palette.primary.contrastText,
            a: {
              color: (theme) => theme.palette.primary.contrastText,
              fontWeight: FONTS.BOLD,
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Hidden mdDown xsDown>
            <Typography component={'span'}>
              <IoCall /> Call for more information:
              <a href="tel:8485848424"> +91 8485 8484 24</a>
            </Typography>
          </Hidden>

          <Typography
            component={'span'}
            textAlign={'center'}
            sx={{
              width: { xs: '100%', lg: 'fit-content', md: 'fit-content' },
            }}
          >
            <Link to="/enquiry"> ENQUIRY </Link> &nbsp; | &nbsp;
            {new Date().getMonth() >= 1 && new Date().getMonth() <= 6 ? (
              <Link to="/entrance/register" className="blink">
                APPLY TO NSAT - {new Date().getFullYear()}{' '}
              </Link>
            ) : (
              <Link to="/testseries/register" className="blink">
                APPLY TO SHAHU SCREENING TEST SERIES -{' '}
                {new Date().getMonth() <= 11
                  ? new Date().getFullYear() + 1
                  : new Date().getFullYear()}{' '}
              </Link>
            )}
            &nbsp; | &nbsp;
            <Link to={AppSettings.STUDENT.loginURL}>LOGIN</Link>
          </Typography>
        </Box>
      </Box>
    </Collapse>
  );
};

export default NotificationBar;
