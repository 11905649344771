import React from 'react';
import { USER_ROLE } from 'constants/AppConstant';

const AdmissionStudentFoundationRegistration = React.lazy(
  () => import('modules/student/admission/admissionStudentRegistrationAdmin')
);
const routes = {
  path: ['/admin/foundation/student/registration'],
  element: <AdmissionStudentFoundationRegistration />,
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  title: 'Foundation Registration',
  type: 'item',
};

export default routes;
