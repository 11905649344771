import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';
const StudentFeesSummary = React.lazy(
  () => import('modules/account/studentFeesReportSummary')
);

const routes = {
  title: 'Fees Summary Report',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/account/feessummary'],
  element: <StudentFeesSummary />,
  type: 'item',
};

export default routes;
