// dispatch types is constant object for dispatch types in reducer
import { DISPATCH_TYPES } from 'constants/ActionTypes';

const reducer = (state, action) => {
  switch (action.type) {
    // It will set state for created record
    case DISPATCH_TYPES.CREATE:
      return {
        item: action.payload,
      };

    // If no case matches then return same state
    default:
      return state;
  }
};

export default reducer;
