import { USER_ROLE } from 'constants/AppConstant';
import React from 'react';

const StudentAssignmentDetails = React.lazy(
  () => import('modules/assignment/studentAssignmentDetails')
);

const routes = {
  element: <StudentAssignmentDetails />,
  title: 'Student Assignment Details',
  type: 'item',
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  path: ['/studentassignments'],
};

export default routes;
